import {createContext, ReactNode, useContext, useEffect, useState} from "react";
import UnderConstruction from "../components/UnderConstruction";
import DefaultLoadingView from "../_utils/fetcher/default-loading-view";
import {Box} from "@mui/material";
import {match} from "ts-pattern";
import {useCookies} from "react-cookie";
import ModalLogin from "../components/modals/LoginModal";
import falsyFetch from "../_utils/falsyFetch";
import Utils from "../utils/Utils";

const AuthContext = createContext<{
    token?: string | null; setToken: (token?: string) => void;
}>(null as any);

export const useAuthContext = () => {
    return useContext(AuthContext);
}


const useToken = () => {
    const [cookies, setCookies] = useCookies(["auth_token"]);
    const [token, _st] = useState<string | undefined>(cookies['auth_token']);


    useEffect(() => {
        _st(cookies['auth_token']);
    }, [cookies]);


    const setToken = (token?: string) => {
        setCookies('auth_token', token || '');
        _st(token || '');
    }

    return {
        token,
        setToken
    }
}
export const AuthProvider = ({children}: { children: ReactNode }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isValidating, setIsValidating] = useState(true);
    const {token, setToken} = useToken();

    const functionHandler = {
        validateLogin() {
            if (!token) {
                console.log('no token found');
                setIsValidating(false);
                setIsLoggedIn(false);
                return;
            }
            setIsValidating(true);


            falsyFetch("/api/auth/validate", {})
                .then(
                    // Utils.safeRetData(
                    (ret) => {
                        if (ret.ret_data) {
                            setIsLoggedIn(true);
                        }
                    }
                    // )
                )
                .catch(Utils.handleCatchError)
                .finally(() => setIsValidating(false));
        },
    };

    useEffect(() => {
        functionHandler.validateLogin();
    }, [token]);

    return (
        <AuthContext.Provider value={{
            token,
            setToken
        }}>
            {match(true as boolean)
                .with(isValidating, () => (
                    <>
                        <Box
                            flex={1}
                            height="100dvh"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <DefaultLoadingView/>
                        </Box>
                    </>
                ))
                .with(!isLoggedIn, () => (
                    <>
                        <Box
                            flex={1}
                            height="100dvh"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <ModalLogin
                                isOpen={true}
                                onClose={() => {
                                }}
                                onLoginSuccess={() => {
                                }}
                            />
                        </Box>
                    </>
                ))
                .otherwise(() => (
                    <>{children}</>
                ))}
        </AuthContext.Provider>
    );
};
