
import { MoreVert } from "@mui/icons-material";
import Clickable from "../Clickable";
import { ClickAwayListener, Menu, MenuItem, MenuProps, SxProps, Theme } from "@mui/material";
import { ReactNode, useRef, useState } from "react";
import * as React from 'react';
// import { styled, alpha } from '@mui/material/styles';
// import Button from '@mui/material/Button';
// import EditIcon from '@mui/icons-material/Edit';
// import Divider from '@mui/material/Divider';
// import ArchiveIcon from '@mui/icons-material/Archive';
// import FileCopyIcon from '@mui/icons-material/FileCopy';
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// const StyledMenu = styled((props: MenuProps) => (
//     <Menu
//         {...props}
//     />
// ))(({ theme }) => ({
//     '& .MuiList-root': {
//         // borderRadius: 6,
//         padding: 0,
//         overflow: 'hidden',
//         paddingBottom: 2,
//     },
// }));

export default function DropDownMenuButton(
    {
        containerStyle = {},
        data,
        children
    }: {
        containerStyle?: React.CSSProperties;
        data: {
            title: string;
            icon: ReactNode;
            onClick: () => void;
        }[];
        // renderItem: (item: any, index: number, close: () => void) => ReactNode;
        children: ReactNode;
    }
) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    // const [menuTop, setMenuTop] = useState(0);
    // const [menuLeft, setMenuTop] = useState(0);

    const buttonRef = useRef(null);



    function onClose() {
        setDropdownOpen(false);
    }


    return (
        <div
            className="relative overflow-visible"
        >
            <Clickable
                ref={buttonRef}
                className="flex items-center justify-center max-w-min rounded-md xbg-[#1038539b] hover:bg-[#4980a585] active:bg-[#1038539b]"
                onClick={() => {
                    setDropdownOpen(true);
                }}>
                {children}
            </Clickable>

            <Menu
                sx={{
                    '& .MuiPaper-root': {
                        borderRadius: 0,
                        padding: 0,
                        overflow: 'hidden',
                        bgcolor: 'transparent',
                        outline: 'none',
                        margin: 0
                    },
                    '& .MuiList-root': {
                        padding: 0,
                        overflow: 'hidden',
                        outline: 'none',
                        margin: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                        backgroundColor: '#053143',
                        borderRadius: '6px',
                        border: '1px solid #dddddd10',
                        ...containerStyle
                    },
                }}
                elevation={1}
                // id="demo-positioned-menu"
                // aria-labelledby="demo-positioned-button"
                anchorEl={buttonRef.current}
                open={dropdownOpen}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {
                    data.map((item, i) => <li
                        key={item.title}
                    >
                        <div
                            className="px-1 py-1 text-[#ddd] bg-[#0f0e0e56]  hover:bg-[#96969670] cursor-pointer text-sm flex gap-1 texft-bold"
                            onClick={() => {
                                onClose();
                                item.onClick();
                            }}
                        >
                            {item.icon}
                            {item.title}
                        </div></li>)
                }

            </Menu>
        </div>
    );

}