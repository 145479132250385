
import { CSSProperties, ReactNode } from "react";
import { Draggable } from "react-beautiful-dnd";


export default function DraggableContainer(
    {
        draggableId,
        index,
        children,
        className,
        containerStyle,
    }: {

        draggableId: string;
        index: number;
        children?: ReactNode;
        className?: string;
        containerStyle?: CSSProperties;
    }

) {


    return (
        <Draggable
            draggableId={String(draggableId)}
            index={index}
        >
            {
                (provided) =>
                (
                    <div
                        style={containerStyle}
                        className={className}

                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                    >
                        {children}

                    </div>
                )
            }
        </Draggable>
    )

}