
/* eslint-disable react/jsx-key */
"use client";
import { useEffect, useState } from "react";
import {
  Class,
  Close,
  Edit,
  EditNote,
  InterpreterMode,
  Looks3,
  Looks4,
  Looks5,
  LooksOne,
  LooksTwo,
  MoreHoriz,
  MoreVert,
  NavigateBefore,
  Person,
  Save,
  ToggleOff,
  ToggleOn,
} from "@mui/icons-material";
import CardInner from "../../components/cards/card/CardInner";
import CardTitle from "../../components/cards/card/CardTitle";
import CardWrapperCl from "../../components/cards/card/CardWrapperCl";
import RoundedLinkButton from "../../components/link/RoundedLinkButton";
import ModalMediaEditor from "../../components/modals/ModalMediaEditor";
import { useParams } from "react-router-dom";
import falsyFetch from "../../_utils/falsyFetch";
import Log from "../../_utils/log/Log";

const looksItems = [
  <LooksOne
    fontSize="large"
    sx={{
      color: "#82cad43d",
    }}
  />,
  <LooksTwo
    fontSize="large"
    sx={{
      color: "#82cad43d",
    }}
  />,
  <Looks3
    fontSize="large"
    sx={{
      color: "#82cad43d",
    }}
  />,
  <Looks4
    fontSize="large"
    sx={{
      color: "#82cad43d",
    }}
  />,
  <Looks5
    fontSize="large"
    sx={{
      color: "#82cad43d",
    }}
  />,
];

export default function ItemOptionSection() {
  // const router = useRouter();
  // const pathname = usePathname();
  // const params = useParams();
  const params = useParams();

  const [navId, setNavId] = useState<number | null>(null);
  const [isNavInabled, setIsNavInabled] = useState(false);
  const [isNavEditing, setIsNavEditing] = useState(false);
  const [isMediaEditorOpen, setIsMediaEditorOpen] = useState(false);


  const [itemName, setItemName] = useState("...");
  const [itemIconSrc, setItemIconSrc] = useState<string | null>(null);
  const [itemNameEditor, setItemNameEditor] = useState("...");
  const [itemIconSrcEditor, setItemIconSrcEditor] = useState<string | null>(
    null
  );
  const [itemContentTypeId, setItemContentTypeId] = useState(0);





  useEffect(() => {
    functionHandler.loadNav(params.nav_item_slug as string);
    // setItemName(("Nav " + params.nav_item_slug) as string);
    // setItemIconSrc(
    //   "https://th.bing.com/th/id/OIP.IwIDS9U72CbO6iDoN_iDsQHaHC?rs=1&pid=ImgDetMain"
    // );
  }, [params]);

  useEffect(() => {
    setItemIconSrcEditor(itemIconSrc);
  }, [itemIconSrc]);

  const functionHandler = {
    updateNavEnabled(e: React.ChangeEvent<HTMLInputElement>) {
      const checked = e.target.checked;
      falsyFetch("/api/nav/upsert", {
        nav_items: [
          {
            nav_item_id: navId,
            nav_item_slug: params.nav_item_slug,
            app_id: Number(params.app_id),
            disabled: checked ? 0 : 1,
          },
        ],
        app_id: Number(params.app_id)
      }).then((res) => {
        if (res.error) {
          return alert(res.error.message);
        }
        setIsNavInabled(checked);
        // Log.exp("res of e: ",checked);
      });
    },
    onNavItemSave() {
      if (itemContentTypeId === 0) {
        alert('Content Type not selected');
      }
      falsyFetch("/api/nav/upsert", {
        nav_items: [
          {
            nav_item_id: navId,
            nav_item_slug: params.nav_item_slug,
            app_id: Number(params.app_id),
            title: itemNameEditor,
            thumbnail: itemIconSrcEditor,
            content_type_id: itemContentTypeId
          },
        ],
        app_id: Number(params.app_id)
      }).then((res) => {
        if (res.error) {
          return alert(res.error.message);
        }

        setItemName(itemNameEditor);
        setItemIconSrc(itemIconSrcEditor);
        setIsNavEditing(false);
        // Log.exp("res of e: ",checked);
      });
    },
    loadNav(nav_item_slug: string) {
      falsyFetch("/api/nav/get-active", {
        // nav_item_id: navId,
        nav_item_slug: params.nav_item_slug,
        app_id: Number(params.app_id),
      }).then((res) => {
        if (res.error) {
          return alert(res.error.message);
        }

        const nav = res.ret_data[0];

        setNavId(nav?.nav_item_id || null);
        setIsNavInabled(nav?.disabled === 0 || false);
        setItemName(nav?.title || params.nav_item_slug);
        setItemContentTypeId(nav?.content_type_id || 0);
        const getThumbnail = () => {
          switch (nav?.content_type_id) {
            case 1:
              return "nav_item_thumbnail_content.png";
            case 2:
              return "nav_item_thumbnail_video.png";
            case 3:
              return "nav_item_thumbnail_dropdown.png";
            default:
              return "/nav-item-thumbnail.png";
          }
        };

        setItemIconSrc("/assets/images/" + getThumbnail());
      });
    },
  };

  return (
    <>
      <ModalMediaEditor
        imageUrl={itemIconSrcEditor}
        onChange={(url: any) => {
          // setItemIconSrcEditor(url);
        }}
        isOpen={isMediaEditorOpen}
        onClose={() => {
          setIsMediaEditorOpen(false);
        }}
      />

      <CardTitle text="Item Options" />
      <div className="pt-3"></div>
      <CardWrapperCl>
        <div className="pt-2 pb-3 px-2  flex flex-row flex-wrap items-center justify-between ">
          <CardTitle text={isNavInabled ? "Enabled" : "Disabled"} />
          <label className="cursor-pointer">
            <input
              className="hidden"
              checked={isNavInabled}
              onChange={functionHandler.updateNavEnabled}
              type="checkbox"
              name=""
              id=""
            />
            {isNavInabled ? (
              <ToggleOn
                fontSize="large"
                sx={{
                  color: "#06e006",
                }}
              />
            ) : (
              <ToggleOff
                fontSize="large"
                sx={{
                  color: "#f15050",
                }}
              />
            )}
          </label>
        </div>
        <CardInner>
          <div className="">
            <div
              className="xw-full text-end cursor-pointer hover:text-[#ffffff93]"
              onClick={() => {
                setItemNameEditor(itemName);
                setItemIconSrcEditor(itemIconSrc);
                setIsNavEditing((o) => !o);
              }}
            >
              {isNavEditing ? <Close /> : <EditNote />}
            </div>

            {isNavEditing && (
              <div className="relative max-w-max mx-auto  xpx-5  my-5 pt-1 pb-5 gap-2 rounded-lg bg-[#ffffff42] flex flex-col items-center justify-center">
                {itemIconSrcEditor ? (
                  <img
                    className="mt-2 rounded-sm overflow-hidden"
                    width={80}
                    height={80}
                    alt={params.nav_item_slug as string}
                    src={itemIconSrcEditor || ""}
                  />
                ) : (
                  <Class
                    sx={{
                      fontSize: 80,
                    }}
                  />
                )}
                <div
                  className="absolute top-[30px] rounded-full p-2 cursor-pointer text-[#192d39] hover:text-[#acdfff] bg-[#ffe9c4a4] hover:bg-[#9f5d47a4] border-2 border-[#ddd]"
                  onClick={() => {
                    setIsMediaEditorOpen(true);
                  }}
                >
                  <Edit fontSize="medium" />
                </div>

                <div className="px-2 mx-2 flex flex-col gap-2 items-stretch justify-start">
                  <input
                    className="rounded-lg p-2 outline-none bg-[#0000005b] focus:bg-[#20202061]"
                    type="text"
                    name=""
                    id=""
                    value={itemNameEditor}
                    placeholder="Title"
                    onChange={(e) => {
                      setItemNameEditor(e.target.value);
                    }}
                  />
                  <select
                    className="rounded-lg p-2 outline-none bg-[#0000005b] focus:bg-[#20202061]"
                    value={itemContentTypeId}
                    onChange={(e) => {
                      setItemContentTypeId(Number(e.target.value));

                    }}>
                    <option
                      className="rounded-lg p-2 outline-none bg-[#0000005b] focus:bg-[#20202061]"
                      value="0" disabled>Content Type</option>
                    <option
                      className="rounded-lg p-2 outline-none bg-[#0000005b] focus:bg-[#20202061]"
                      value="1">Doc</option>
                    <option
                      className="rounded-lg p-2 outline-none bg-[#0000005b] focus:bg-[#20202061]"
                      value="2">Video</option>
                    <option
                      className="rounded-lg p-2 outline-none bg-[#0000005b] focus:bg-[#20202061]"
                      value="3">Dropdown</option>
                  </select>
                  <div className="pt-2"></div>
                  <RoundedLinkButton
                    icon={<Save />}
                    title="Save"
                    onClick={functionHandler.onNavItemSave}
                  />
                </div>
              </div>
            )}

            <div className="max-w-max p-2 rounded-lg overflow-hidden flex flex-row items-center justify-center gap-1 bg-dark-blue-300">
              {looksItems.map((look, index) => {
                return params.nav_item_slug === "item" + (index + 1) ? (
                  <div
                    className="text-center flex flex-col items-center justify-between"
                    key={index}
                  >
                    {itemIconSrc ? (
                      <img
                        className="mt-2 rounded-sm overflow-hidden"
                        width={26}
                        height={26}
                        alt={params.nav_item_slug}
                        src={itemIconSrc}
                      />
                    ) : (
                      look
                    )}

                    <div className="mt-0">{itemName}</div>
                  </div>
                ) : (
                  <div
                    className="text-center flex flex-col items-center justify-between"
                    key={index}
                  >
                    {look}
                    <div className="mx-1 w-[50px] h-[8px] bg-[#82cad423] rounded-lg"></div>
                  </div>
                );
              })}
            </div>
          </div>
        </CardInner>
      </CardWrapperCl>
    </>
  );
}
