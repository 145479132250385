'use client';
import { useState } from "react";
import CardWrapper from "../card/CardWrapper";

export default function CardPopular({ limit }: { limit: number }) {
    const [userCount, setUserCout] = useState(0);
    const [appName, setAppName] = useState('...');




    return (
        <CardWrapper>
            <div className="pt-2 text-white font-semibold" >
                <p className="pb-2 text-xs font-semibold text-gray-500">Popular <span className="text-[10px]">( {limit == 1 ? 'today' : `last ${limit} days`} )</span></p>
                <span>{userCount || '...'}</span> (<span className="text-sm font-bold text-cyan-600 text-ellipsis">{appName}</span>)

            </div>
        </CardWrapper>)
}