"use client";

import { Box, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useRoutes } from "react-router-dom";
import AppLayout from "./app-layout";
import CardTitle from "../../components/cards/card/CardTitle";
import CardPopular from "../../components/cards/dashboard/CardPopular";
import CardWrapper from "../../components/cards/card/CardWrapper";
import { Error } from "@mui/icons-material";
import falsyFetch from "../../_utils/falsyFetch";
import Utils from "../../utils/Utils";
import { CustomTypes } from "../../_types/CustomTypes";
import ElevatedButton from "../../components/button/ElevatedButton";






export default function AppDetails() {
  // eslint-disable-next-line no-restricted-globals
  const [appId, setAppId] = useState(-1);
  const [appName, setAppName] = useState("");
  const [appSlug, setAppSlug] = useState("");
  const [packageName, setPackageName] = useState("");
  // const pathname = location.pathname;
  const { app_id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    falsyFetch("/api/app/paginate", {
      app_id: app_id,
      limit: 1,
      offset: 0,
    })
      .then(
        Utils.safeRetData((data: CustomTypes.AppType[]) => {
          if (data.length === 0) {
            alert("App not found!");
            return;
          }
          const app = data[0];

          if (!app) return;

          setAppId(app.app_id);
          setAppName(app.title);
          setAppSlug(app.slug);
          setPackageName(app.package_name);
        })
      )
      .catch(Utils.handleCatchError);
  }, [app_id]);

  const handleDeleteApp = () => {
    const userInput = window.prompt(`Please enter '${appName}' to confirm deletion. `, '');
    if (userInput === null) return;
    if (userInput !== appName) return alert('App name could not match. Please try again.');


    falsyFetch("/api/app/delete", {
      app_id: app_id,
    })
      .then(
        Utils.safeRetData((data) => {
          alert('App deleted successfully');
          navigate('/apps');
        })
      )
      .catch(Utils.handleCatchError);

  }

  return (
    <AppLayout>
      <main className="xpx-1">
        <div className="pt-2"></div>

        <div className="flex items-center justify-between">
          <div>
            <CardTitle text={appName} />
            <p>{appSlug}</p>
          </div>
          <div className="p-3">
            <ElevatedButton color="#e733339d" onClick={handleDeleteApp}>
              Delete app
            </ElevatedButton>
          </div>
        </div>
        {/* <p>{packageName}</p> */}

        <br />
        <hr />
        <br />
        <CardTitle text="Popularity" />
        <div className="py-2 my-2 flex flex-col md:flex-row gap-2 min-h-max">
          <div className="flex flex-row md:flex-col flex-wrap gap-2 items-stretch content-stretch min-w-40">
            <CardPopular limit={30} />
            <CardPopular limit={7} />
            <CardPopular limit={1} />
          </div>

          <div className="flex-grow flex-shrink">
            <CardWrapper>
              <div className="h-[226px] py-2">
                <CardTitle text="Statistics" />
                <div className=" text-center xrelative top-[50%]  xtranslate-y-[-50%]">
                  <Error
                    fontSize="large"
                    sx={{
                      fontSize: 80,
                      p: 2,
                      borderRadius: "50%",
                      backgroundColor: "#00000015",
                    }}
                  />
                  <div className="text-[#4979c0] font-bold text-2xl pt-5 pb-2">
                    Oops!
                  </div>
                  <div className="text-xs">
                    Look like this section is not done yet.
                  </div>
                </div>
              </div>
            </CardWrapper>
          </div>
        </div>

        <CardTitle text="Requests" />

        <div className="pt-3"></div>

        <CardWrapper hover>
          <div className="flex flex-nowrap w-full gap-2 justify-between p-4">
            <div className="flex-grow-1">
              <h1>Nothing to show here.</h1>
            </div>
            <div className="inline-block  h-[90%] w-[3px] bg-[#4fbccd]"></div>
          </div>
        </CardWrapper>
      </main>
    </AppLayout>
  );
}
