
import Constants from "../../utils/Constants";


const Log = {
  exp(...args: any) {
    if (!Constants.experimentalLogEnabled) {
      return;
    }
    console.log(...args);
  },
};

export default Log;

