const Constants = {
  experimentalLogEnabled: true,

  baseAPIUrl: "https://api.pharmacistbhai.com",
  // baseAPIUrl: "http://localhost:8000",

  images: {
    // userProfile: require("../assets/images/user.png"),
  },
  icons: {
    // messages: require("../assets/icons/message.png"),
  },
  key: {
    CookiesKey: {
      loginToken: "auth_token",
      loginType: "login-type",
      campusInfo: "campus-info",
    },
    responseType: {
      valid: "valid",
      success: "success",
    },
  },
  colors: {
    halfTransparentBlack: "#02020288",
    lightTheme: {
      palette: {
        mode: "light",
        primary: {
          main: "#0073a8",
        },
        secondary: {
          main: "#017a64",
        },
        success: {
          main: "#0ad433",
        },
        warning: {
          main: "#e69d00",
        },
        error: {
          main: "#f00",
        },
        background: {
          default: "#f5f5f5",
          paper: "#fff",
        },
        text: {
          primary: "#333",
          secondary: "#a8a8a8",
          onPrimary: "white",
          onSecondary: "#ffdc7a",
        },
      },
    },
    darkTheme: {
      palette: {
        mode: "dark",
        primary: {
          main: "#ffa726",
        },
        secondary: {
          main: "#f57c00",
        },
        background: {
          default: "#222",
          paper: "#333",
        },
        text: {
          primary: "#fff",
          secondary: "#ccc",
        },
      },
    },
  },
};

export default Constants;
