
"use client";

import { Box } from "@mui/material";
import { forwardRef, useEffect, useRef } from "react";

function ElevatedButton({
    color = 'transparent',
    children,
    onClick,
    className,
}: {
    color?: string;
    className?: string;
    onClick: () => void;
    children?: any
}, ref: any) {

    const mref = useRef(null);

    // useEffect(() => {

    //     ref = mref;

    //     const r = mref;

    //     if (r && r.current) {
    //         (r.current as any).style.backgroundColor = color;
    //     }
    // }, [mref]);

    return (
        <Box
            ref={ref}
            // ref={(r) => {
            //     r && r..style.backgroundColor = color;

            //     ref = r;
            // }}
            sx={{
                backgroundColor: color || '#dddddd15',
            }}
            className={` ${(className || "")}  rounded-lg  m-1   cursor-pointer shadow-sm shadow-[#dddddd50]  overflow-hidden`}
            onClick={onClick}
        >
            <Box sx={{
                '&:hover': {
                    backgroundColor: '#f1f1f125'
                },
                '&:active': {
                    backgroundColor: '#5a5a5a1f'
                }
            }}

                className={`  py-1 px-2 select-none  flex flex-row gap-1`}
            >

                {children}
            </Box>


        </Box>
    )
}


export default forwardRef(ElevatedButton);

