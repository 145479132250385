"use client";

import {CircularProgress, ClickAwayListener} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import CardWrapper from "../cards/card/CardWrapper";
import CardInner from "../cards/card/CardInner";
import UnderConstruction from "../UnderConstruction";
import CardTitle from "../cards/card/CardTitle";
import RoundedLinkButton from "../link/RoundedLinkButton";
import * as validator from 'email-validator';
import {
    CancelRounded,
    CheckCircleRounded,
    Close,
    Create,
    Save,
} from "@mui/icons-material";

import TextInputNormal from "../input/TextInputNormal";
import React from "react";
import falsyFetch from "../../_utils/falsyFetch";
import Utils from "../../utils/Utils";
import ModalActionDialog from "./ModalActionDialog";
import {CustomTypes} from "../../_types/CustomTypes";
import {useCookies} from "react-cookie";
import {useAuthContext} from "../../providers/AuthProvider";

type ModalProps = {
    onClose: () => void;
    isOpen: boolean;
    onLoginSuccess: (apId: number) => void;
};

export default function ModalLogin({onClose, isOpen, onLoginSuccess}: ModalProps) {
    const [email, setEmail] = useState("");
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [password, setPassword] = useState("");
    const [errMessage, setErrMessage] = useState("");

    const {setToken, token} = useAuthContext();


    useEffect(() => {
        setErrMessage('')
    }, [email, password]);
    const functionHandler = {

        replaceNonMatchingCharacters(str: string) {
            // Replace any characters that don't match the regex with an empty string
            const rx = /[^a-z0-9_-]/g;
            // alert(rx.source);
            return str.replace(rx, "");
        },
        handleLogin() {
//          validate email and password

            if (!validator.validate(email)) {
                setErrMessage("Please enter a valid email");
                return;
            }

            if (password.length <= 1) {
                setErrMessage("Invalid password length")
                return;
            }

            setIsLoggingIn(true);
            const payload = {
                email,
                password
            }
            falsyFetch("/api/auth/login", payload)
                .then(
                    Utils.safeRetData((data) => {
                        setToken(data.token);
                    })
                )
                .catch(Utils.handleCatchError)
                .finally(() => setIsLoggingIn(false));

        },
        validateSlug(slug: string) {
            //   if (timeoutId) {
            //     clearTimeout(timeoutId);
            //   }
            //   setSlugValidationLoading(true);
            //   setIsSlugValid(false);
            //   const newTimeoutId = setTimeout(() => {
            //     falsyFetch(
            //       "/api/slug/validate",
            //       {
            //         slug: slug,
            //       }
            //       // (payload) => {
            //       //   return {
            //       //     ret_data: {
            //       //       slug: payload.slug,
            //       //       is_valid: true,
            //       //     },
            //       //     error: null,
            //       //   };
            //       // }
            //     )
            //       .then(
            //         Utils.safeRetData((data) => {
            //           //data to do anything.
            //           if (!data.is_valid) {
            //             setAppSlugError("The slug can't be used.");
            //           }
            //           setIsSlugValid(data?.is_valid || false);
            //         })
            //       )
            //       .catch(Utils.handleCatchError)
            //       .finally(() => setSlugValidationLoading(false));
            //   }, 500);
            //   setTimeoutId(newTimeoutId);
        },
    };

    return (
        <div
            id="modal-media-editor-top"
            className={`${
                isOpen ? "block" : "hidden"
            } fixed left-0 top-0 w-dvw h-dvh overflow-hidden bg-[#0000006e] z-50
            flex flex-col items-center justify-center
            `}
        >
            <ModalActionDialog
                title="Info!"
                hideCloseButton
                isOpen={isLoggingIn}
                onClose={() => {
                }}
                actions={[]}
                innerContent={
                    <div className="flex flex-col items-center justify-center gap-3">
                        <CircularProgress
                            sx={{
                                color: "inherit",
                            }}
                            size={38}
                            thickness={6}
                        />
                        <span>Please wait while logging in.</span>
                    </div>
                }
            />
            {isOpen && (
                <ClickAwayListener
                    onClickAway={(e) => {
                        // onClose();
                    }}
                >
                    <div
                        className="rounded-lg border-2 border-[#ffffff7b] shadow-md
                    max-w-[450px] max-h-[95dvh] overflow-y-auto w-full"
                    >
                        <div className="w-full">
                            <div className="bg-dark-blue-100 p-3">
                                <div
                                    className="sticky top-0 z-10 bg-dark-blue-100 flex items-center pt-2 justify-between px-2">
                                    <CardTitle text="Login"/>
                                </div>
                                <div className="pt-2"></div>

                                <CardInner>
                                    <div className="w-full">
                                        <p className="pb-2">Email:</p>

                                        <TextInputNormal
                                            fullWidth
                                            type="email"
                                            value={email}
                                            placeholder="mail@domain.com"
                                            onChange={(e) => {
                                                const email = e.target.value;
                                                setEmail(email);
                                            }}
                                        />

                                        <p className="pb-2">Password:</p>

                                        <TextInputNormal
                                            fullWidth
                                            type="password"
                                            value={password}
                                            placeholder="password"
                                            // errorMessage={appSlugError}
                                            onChange={(e) => {
                                                const val = e.target.value;

                                                setPassword(val);
                                            }}
                                        />

                                        <span className="block xabsolute p-1 bottom-0 left-0 text-[#e9a33a] text-sm">
                      {errMessage ? "** " + errMessage : null}
                    </span>

                                        <div className="p-6"></div>

                                        <RoundedLinkButton
                                            //   disabled={!isSlugValid}
                                            className="self-center"
                                            icon={
                                                null
                                                // <Create
                                                //   sx={{
                                                //     fontSize: 16,
                                                //   }}
                                                // />
                                            }
                                            title="Login"
                                            onClick={functionHandler.handleLogin}
                                        />

                                        {/* <div className="p-3"></div> */}
                                    </div>
                                </CardInner>
                            </div>
                        </div>
                    </div>
                </ClickAwayListener>
            )}
        </div>
    );
}
