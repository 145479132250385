"use client";

import { ClickAwayListener } from "@mui/material";
import { DragEvent, useEffect, useMemo, useRef, useState } from "react";
import CardWrapper from "../cards/card/CardWrapper";
import CardInner from "../cards/card/CardInner";
import UnderConstruction from "../UnderConstruction";
import CardTitle from "../cards/card/CardTitle";
import RoundedLinkButton from "../link/RoundedLinkButton";
import {
  Close,
  Done,
  DragIndicator,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUp,
  RoundedCorner,
  Save,
} from "@mui/icons-material";
import TextInputNormal from "../input/TextInputNormal";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
  ResponderProvided,
} from "react-beautiful-dnd";
import DraggableContainer from "./drag-drop-ui/DraggableContainer";
import DroppableContainer from "./drag-drop-ui/DroppableContainer";
import SummerNoteEditor from "../summernote/SummernoteEditor";
import MultiselectButton from "../button/MultiselectButton";
import { VideoContentEditor } from "./content-editor/VideoContentEditorInner";
import { DropdownContentEditor } from "./content-editor/DropdownContentEditorInner";
import { CustomTypes } from "../../_types/CustomTypes";
import falsyFetch from "../../_utils/falsyFetch";
import Utils from "../../utils/Utils";
import ModalActionDialog from "./ModalActionDialog";
import Log from "../../_utils/log/Log";
import _ from "lodash";
import { useParams } from "react-router-dom";

type UniqueArray<U> = U extends readonly [infer X, ...infer Rest]
  ? InArray<Rest, X> extends true
    ? ["Encountered value with duplicates:", X]
    : readonly [X, ...UniqueArray<Rest>]
  : U;

type InArray<U, X> = U extends readonly [X, ...infer _Rest]
  ? true
  : U extends readonly [X]
  ? true
  : U extends readonly [infer _, ...infer Rest]
  ? InArray<Rest, X>
  : false;

export default function ModalContentEditor<T extends Record<string, any>>({
  content,
  onClose,
  isOpen,
  onChange,
}: {
  content: CustomTypes.ContentType | null;
  onClose: () => void;
  isOpen: boolean;
  onChange: (changedContent: CustomTypes.ContentType) => void;
}) {
  const [mContent, setMContent] = useState<CustomTypes.ContentType | null>(
    null
  );
  const [isCloseDialogVisible, setIscloseDialogVisible] = useState(false);

  // const [mDoc, setMDoc] = useState<CustomTypes.ContentDocType | null>(null);
  // const [mVideo, setMVideo] = useState<CustomTypes.ContentVideoType | null>(
  //   null
  // );
  // const [mDropdown, setMDropdown] = useState<CustomTypes.ContentDocType | null>(
  //   null
  // );

  const [isToolbarVisible, setisToolbarVisible] = useState(true);

  const params = useParams();

  const parentRef = useRef(null);
  const editorRef = useRef(null);

  // const scrollerRef = useRef(null);

  //     () => filterBy === 'drag_drop' ? mDragDropItems : mSortItems,
  //     [filterBy, sortBy, mDragDropItems, mSortItems]);

  // const [callback, setCallback] = useState<any>(null);

  // useEffect(() => {

  //     if (filterBy !== 'drag_drop') {

  //         setMSortItems(o => {

  //             let x = [...o];

  //             return x.sort((ae: any, be: any) => {
  //                 let compare = 0;

  //                 const a = ae[filterBy];
  //                 const b = be[filterBy];

  //                 if (typeof a === 'string') {
  //                     compare = String(a).localeCompare(String(b));
  //                 }
  //                 if (typeof a === 'number'
  //                     || typeof a === 'bigint') {
  //                     compare = Number(a) - (Number(b) || 0)
  //                 }
  //                 if (typeof a === 'boolean') {
  //                     compare = (a && !b) ? 1 : (!a && b) ? -1 : 0
  //                 }

  //                 return sortBy === 'asc' ? compare : -compare;
  //             });

  //         });

  //     }

  // }, [sortBy, filterBy]);

  useEffect(() => {
    // setMDragDropItems(items);
    // setMSortItems(items);
    setMContent(_.cloneDeep(content));
  }, [isOpen, content]);

  useEffect(() => {
    if (isOpen) setisToolbarVisible(true);
  }, [isOpen]);

  const functionHandler = {
    onCloseRequest() {
      if (mContent?.content_type_id === 1) {
        const code = (editorRef.current as any).getCode();
        mContent.doc = code;
      }
      if (_.isEqual(content, mContent)) {
        onClose();
        return;
      }

      setIscloseDialogVisible(true);
    },
    saveDoc(doc: CustomTypes.ContentDocType) {
      const code = (editorRef.current as any).getCode();
      // console.log("code is: ", code);

      const payload = {
        category_id: doc.category_id,
        content_id: doc.content_id,
        doc: code,
        app_id: Number(params.app_id),
      };

      falsyFetch("/api/content/upsert-doc", payload)
        .then((res) => {
          if (res.error) {
            return Utils.handleCatchError(res.error);
          }

          alert("Document has been saved successfully");
          onChange({
            ...doc,
            ...payload,
          });
        })
        .catch(Utils.handleCatchError);
    },
    saveVideo(video: CustomTypes.ContentVideoType) {
      // console.log("code is: ", code);

      const payload = {
        ...video,
        app_id: Number(params.app_id),
      };

      falsyFetch("/api/content/upsert-video", payload)
        .then((res) => {
          if (res.error) {
            return Utils.handleCatchError(res.error);
          }

          alert("Document has been saved successfully");
          onChange(video);
        })
        .catch(Utils.handleCatchError);
    },

    saveDropdown(dropdown: CustomTypes.ContentDropdownType) {
      // console.log("code is: ", code);

      const payload = {
        ...dropdown,
        app_id: Number(params.app_id),
      };

      falsyFetch("/api/content/upsert-dropdown", payload)
        .then((res) => {
          if (res.error) {
            return Utils.handleCatchError(res.error);
          }

          alert("Document has been saved successfully");
          onChange(dropdown);
        })
        .catch(Utils.handleCatchError);
    },

    saveContent(changedContent?: CustomTypes.ContentType) {
      const c = changedContent || mContent;
      Log.exp("content: ", c);
      if (!c) return;

      if (c.content_type_id === 1) {
        functionHandler.saveDoc(c as CustomTypes.ContentDocType);
      } else if (c.content_type_id === 2) {
        functionHandler.saveVideo(c as CustomTypes.ContentVideoType);
      }else if (c.content_type_id === 3) {
        functionHandler.saveDropdown(c as CustomTypes.ContentDropdownType);
      }
    },
  };

  return (
    <div
      id="modal-media-editor-top"
      className={`${
        isOpen ? "block" : "hidden"
      } fixed left-0 top-0 w-dvw h-dvh overflow-hidden bg-[#0000006e] z-50
            flex flex-col items-center justify-center
            `}
    >
      <ModalActionDialog
        isOpen={isCloseDialogVisible}
        title="Warning!"
        innerContent={
          <p>
            All changes will be reverted if you close without saving. Do you
            want to close?
          </p>
        }
        actions={[
          {
            title: "Close",
            position: "right",
            onClick() {
              onClose();
            },
          },
          {
            title: "Cancel",
            position: "right",
            onClick() {
              setIscloseDialogVisible(false);
            },
          },
        ]}
        onClose={() => {
          setIscloseDialogVisible(false);
        }}
      />

      {isOpen && (
        <ClickAwayListener
          onClickAway={(e) => {
            // onClose();
          }}
        >
          <div
            className="rounded-lg border-2 border-[#ffffff7b] shadow-md
                    xmax-w-max xmax-h-[95dvh]  overflow-y-hidden  w-dvw h-dvh sm:w-[95dvw] sm:h-[95dvh]"
            // ref={scrollerRef}
          >
            <div className="w-full h-full" id="div-parent">
              <div
                className={` scrollbar bg-dark-blue-100 w-full h-full xpx-2 xflex xflex-col ${
                  mContent?.content_type_id !== 1 ? " overflow-auto " : ""
                }`}
                ref={parentRef}
              >
                <div className="sticky top-0 pb-2 bg-dark-blue-100 ">
                  <div className=" z-10 xbg-dark-blue-100 flex items-center pt-2 justify-between px-2">
                    <CardTitle text="Edit Content" />
                    <RoundedLinkButton
                      icon={<Close fontSize="small" />}
                      onClick={functionHandler.onCloseRequest}
                    />
                  </div>
                  <div className="pt-2"></div>

                  {isToolbarVisible && (
                    <div className="w-full flex items-center justify-between gap-2 px-2">
                      <MultiselectButton
                        selectedIndex={(mContent?.content_type_id || 1) - 1}
                        items={["Document", "Video", "Dropdown"]}
                        onChange={(index, title) => {
                          setMContent((o) => ({
                            ...(o as any),
                            content_type_id: index + 1,
                          }));
                        }}
                      />

                      {mContent?.content_type_id === 1 && (
                        <RoundedLinkButton
                          icon={<Save fontSize="small" />}
                          title="Save"
                          onClick={() => functionHandler.saveContent()}
                        />
                      )}
                    </div>
                  )}
                </div>

                <div className="xpt-5 "></div>
                <div className="h-full">
                  {mContent?.content_type_id === 1 ? (
                    <SummerNoteEditor
                      // parentId="div-parent"
                      ref={editorRef}
                      parentRef={parentRef}
                      className="w-full h-full"
                      content={mContent}
                    />
                  ) : mContent?.content_type_id === 2 ? (
                    <VideoContentEditor
                      content={mContent as CustomTypes.ContentVideoType}
                      onUpdateToolbar={(isVisible) => {
                        setisToolbarVisible(isVisible);
                      }}
                      onSave={functionHandler.saveContent}
                      onChange={(cc) => setMContent(cc)}
                    />
                  ) : mContent?.content_type_id === 3 ? (
                    <DropdownContentEditor
                      content={mContent as CustomTypes.ContentDropdownType}
                      onUpdateToolbar={(isVisible) => {
                        setisToolbarVisible(isVisible);
                      }}
                      onSave={functionHandler.saveContent}
                      onChange={(cc) => setMContent(cc)}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
}
