"use client";

import {
    AppBlocking,
    Apps,
    ArrowRight,
    Dashboard,
    DashboardCustomizeRounded,
    ExpandMore,
    Home,
    KeyboardArrowRight,
    KeyboardDoubleArrowLeft,
    KeyboardDoubleArrowRight,
    Logout,
    Menu,
    MenuOpen,
    Settings,
    SpaceDashboardRounded,
} from "@mui/icons-material";

import DrawerFullWidthLink from "./link/DrawerFullWidthLink";
import {useState} from "react";
import {useAuthContext} from "../providers/AuthProvider";

export default function NavigationDrawer() {
    const [isOpen, setIsOpen] = useState(true);
    const {setToken} = useAuthContext();

    return (
        <nav
            className={`xstatic xtop-0 p-2 pl-3 overflow-x-visible transition-all duration-300 ease-in-out ${
                isOpen ? "w-56" : "w-16"
            } h-full xh-dvh max-w-full flex flex-col gap-1.5 flex-shrink-0  overflow-y-auto scrollbar bg-dark-blue-100`}
        >
            <div
                className={`cursor-pointer mb-6 mt-5 p-2 rounded-md w-full flex ${
                    isOpen ? "justify-between gap-3" : "justify-center"
                } bg-dark-blue-300  hover:bg-light-blue-transparent`}
                onClick={() => {
                    setIsOpen((o) => !o);
                }}
            >
                {/* <SpaceDashboardRounded /> */}

                {isOpen && (
                    <>
                        <SpaceDashboardRounded/>
                        <div className="font-bold">Dashboard</div>
                    </>
                )}
                {isOpen ? <KeyboardDoubleArrowLeft/> : <KeyboardDoubleArrowRight/>}

                {/* 
                <div className="border-2 border-solid rounded-full absolute right-[-22px] top-[18px] z-10">
                    <KeyboardArrowRight />
                </div> 
        */}
            </div>

            <DrawerFullWidthLink
                startIcon={<Home/>}
                title="Home"
                href="/"
                isOpen={isOpen}
            />
            <DrawerFullWidthLink
                startIcon={<Apps/>}
                title="All Apps"
                href="/apps"
                isOpen={isOpen}
            />
            <DrawerFullWidthLink
                startIcon={<Settings/>}
                title="Settings"
                href="/settings"
                isOpen={isOpen}
            />
            <div className="pt-5"></div>
            {isOpen && (
                <div className="text-center w-full text-sm text-[#486f91]">
                    version : 1.0.0
                </div>
            )}
            <DrawerFullWidthLink
                startIcon={<Logout/>}
                title="Log Out"
                outline={true}
                onClick={() => {
                    const action = window.confirm("Are you sure! you want to logout?");
                    if (action) {
                        setToken();
                    }
                }}
                isOpen={isOpen}
                center={true}
            />
        </nav>
    );
}

