
import { CircularProgress } from "@mui/material";


export default function DefaultLoadingView() {
    return (
        <div className="w-full h-full flex flex-col items-center justify-around gap-2 p-4">
            <CircularProgress color="inherit" />
            {/* <p>Loading...</p> */}
        </div>
    )

}