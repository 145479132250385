
"use client";

import {
  AdsClick,
  Ballot,
  Bookmark,
  Home,
  HowToReg,
  KeyboardArrowDown,
  KeyboardArrowUp,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  Label,
  MiscellaneousServices,
  MoreHoriz,
  MoreVert,
  People,
  Settings,
  StackedLineChart,
  Store,
} from "@mui/icons-material";
// import Image from "next/image";
// import Link from "next/link";
import DrawerFullWidthLink from "./link/DrawerFullWidthLink";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { useParams, usePathname, useRouter } from "next/navigation";

export default function AppsNavigationDrawer() {
  // const pathname = usePathname();
  // const router = useRouter();
  const { app_id, nav_item_slug } = useParams();
  // eslint-disable-next-line no-restricted-globals
  const pathname = location.pathname;
  const navigate = useNavigate();

  const [cbNavChecked, setCbNavChecked] = useState(false);
  const [cbUsersChecked, setCbUsersChecked] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setCbNavChecked(new RegExp(app_id + "/nav/.+", "i").test(pathname));
    setCbUsersChecked(new RegExp(app_id + "/users/.+", "i").test(pathname));
  }, [pathname, app_id]);

  return (
    <nav
      className={` xstatic xtop-0 p-2 pl-3 overflow-x-visible transition-all duration-300 ease-in-out ${isOpen ? "w-56" : "w-16"
        } h-full xh-dvh max-w-full flex flex-col gap-1.5 flex-shrink-0  overflow-y-auto scrollbar bg-dark-blue-100`}
    >
      <div
        className={`cursor-pointer p-2 rounded-md w-max flex ${isOpen ? "justify-between gap-3" : "justify-center"
          } bg-dark-blue-300  hover:bg-light-blue-transparent`}
        onClick={() => {
          navigate('/')
        }}
      >
        <Home />
      </div>


      <div
        className={`cursor-pointer  mt-5  mb-6 p-2 rounded-md w-full flex ${isOpen ? "justify-between gap-3" : "justify-center"
          } bg-dark-blue-300  hover:bg-light-blue-transparent`}
        onClick={() => {
          setIsOpen((o) => !o);
        }}
      >
        {isOpen && (
          <>
            <MiscellaneousServices /> <div className="font-bold">Features</div>
          </>
        )}
        {isOpen ? <KeyboardDoubleArrowLeft /> : <KeyboardDoubleArrowRight />}
      </div>


      <DrawerFullWidthLink
        startIcon={<StackedLineChart />}
        title="Info"
        href={`/apps/${app_id}`}
        isOpen={isOpen}
      />

      {/* <div>
        <label className="peer group select-none">
          <input
            className="hidden"
            type="checkbox"
            checked={cbUsersChecked}
            onChange={(e) => setCbUsersChecked(e.target.checked)}
          />
          <DrawerFullWidthLink
            startIcon={<People />}
            endIcon={
              cbUsersChecked ? <KeyboardArrowUp /> : <KeyboardArrowDown />
            }
            title="Users"
            // href="/dashboard/apps"
            isOpen={isOpen}
          />
        </label>

        {cbUsersChecked && (
          <div
            className={`h-min ms-2 ps-2 border-l-2 border-l-[#3f5862]  overflow-hidden`}
          >
            <DrawerFullWidthLink
              startIcon={<HowToReg />}
              title="Authentication"
              href={`/apps/${app_id}/users/auth`}
              isOpen={isOpen}
            />
            <DrawerFullWidthLink
              startIcon={<Store />}
              title="Membership"
              href={`/apps/${app_id}/users/membership`}
              isOpen={isOpen}
            />
          </div>
        )}
      </div> */}

      <div>
        <label className="peer group select-none">
          <input
            className="hidden"
            type="checkbox"
            checked={cbNavChecked}
            onChange={(e) => setCbNavChecked(e.target.checked)}
          />
          <DrawerFullWidthLink
            startIcon={isOpen ? <MoreHoriz /> : <MoreVert />}
            endIcon={cbNavChecked ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            title="Navigations"
            // href="/dashboard/apps"
            isOpen={isOpen}
          />
        </label>

        {cbNavChecked && (
          <div
            className={` h-min  ms-2 ps-2 border-l-2 border-l-[#3f5862]  overflow-hidden`}
          >
            <DrawerFullWidthLink
              startIcon={<Label />}
              title="Nav 1"
              href={`/apps/${app_id}/nav/item1`}
              isOpen={isOpen}
            />
            <DrawerFullWidthLink
              startIcon={<Label />}
              title="Nav 2"
              href={`/apps/${app_id}/nav/item2`}
              isOpen={isOpen}
            />
            <DrawerFullWidthLink
              startIcon={<Label />}
              title="Nav 3"
              href={`/apps/${app_id}/nav/item3`}
              isOpen={isOpen}
            />
            <DrawerFullWidthLink
              startIcon={<Bookmark />}
              title="Bookmark"
              href={`/apps/${app_id}/nav/bookmark`}
              isOpen={isOpen}
            />
            <DrawerFullWidthLink
              startIcon={<Settings />}
              title="Settings"
              href={`/apps/${app_id}/nav/settings`}
              isOpen={isOpen}
            />
            <DrawerFullWidthLink
              startIcon={<Ballot />}
              title="More Apps"
              href={`/apps/${app_id}/nav/more-app`}
              isOpen={isOpen}
            />
          </div>
        )}
      </div>

      {/* <DrawerFullWidthLink
        startIcon={<AdsClick />}
        title="Ads"
        href="/dashboard/settings"
        isOpen={isOpen}
      /> */}

      <div className="pt-5"></div>
      {isOpen && (
        <div className="text-center w-full text-sm text-[#486f91]">
          version : 1.0.0
        </div>
      )}
      {/* <DrawerFullWidthLink
                startIcon={<Logout />}
                title="Log Out"
                outline={true}
                onClick={() => {
                    alert('loggin out...');
                }}
                isOpen={isOpen}
                center={true}
            /> */}
    </nav>
  );
}

