import { Construction } from "@mui/icons-material";
// import { useParams, usePathname, useRouter } from "next/navigation";


export default function MoreAppsFragment() {

    // const router = useRouter();
    // const pathname = usePathname();
    // const param = useParams();


    return (

        <main className="h-full w-full">
            <div className=" text-center relative top-[50%]  translate-y-[-50%]">
                <Construction fontSize="large" sx={{
                    fontSize: 120,
                    p: 4,
                    borderRadius: '50%',
                    backgroundColor: '#00000015'

                }} />
                <div className="text-[#4979c0] font-bold text-2xl pt-5 pb-2">Oops!</div>
                <div className="text-xs">This page is under construction.</div>
            </div>
        </main>
    )
}