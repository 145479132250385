
import { CSSProperties, ReactNode } from "react";
import { Droppable } from "react-beautiful-dnd";


export default function DroppableContainer(
    {
        droppableId,
        children,
        className,
        containerStyle,
    }: {

        droppableId: string;
        children?: ReactNode;
        className?: string;
        containerStyle?: CSSProperties;
    }

) {


    return (
        <Droppable
            droppableId={droppableId}

        >
            {
                (provided) =>
                (
                    <div
                        style={containerStyle}
                        className={className}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {children}
                        {provided.placeholder}
                    </div>

                )
            }
        </Droppable>
    )

}