
import { useMemo } from "react";
import { useNavigate, useRoutes } from "react-router-dom";

export default function DrawerFullWidthLink({
  startIcon,
  endIcon,
  title,
  href,
  active = false,
  isOpen,
  outline = false,
  onClick = () => {},
  center = false,
}: {
  startIcon: any;
  endIcon?: any;
  title: string;
  href?: string;
  active?: boolean;
  isOpen: boolean;
  outline?: boolean;
  onClick?: () => void;
  center?: boolean;
}) {
  // eslint-disable-next-line no-restricted-globals
  const pathname = location.pathname // usePathname();
  const navigate = useNavigate();

  active = href === pathname;

  const child = (
    <div
      className={`flex ${
        isOpen ? "gap-3" : "gap-0"
      } flex-row rounded-md w-full flex-nowrap text-center border-2 border-transparent ${
        isOpen ? "px-2" : "xpx-1  justify-center"
      } py-1 ${center ? "justify-center" : ""} ${
        active ? `bg-[#4d637260] ` : `bg-[#1a273114]`
      }  hover:border-[#22385c7c] active:bg-[#40505b66] ${
        outline ? "border-[#93b4e964]" : "outline-none"
      } focus:ring focus:ring-[#1c3c5100] `}
    >
      {startIcon}
      {isOpen && <span>{title}</span>}

      {endIcon && (
        <div className="w-full flex flex-row items-center justify-end">
          {endIcon}
        </div>
      )}
    </div>
  );

  // return <></>;

  return href ? (
    <div
      className="cursor-pointer"
      onClick={() => {
        navigate(href);
      }}
    >
      {child}
    </div>
  ) : (
    <div className="cursor-pointer" onClick={onClick}>
      {child}
    </div>
  );
}

