
import { Construction, Error } from "@mui/icons-material";
import { Box, Container, Typography } from "@mui/material";

import CardTitle from "../../components/cards/card/CardTitle";
import CardWrapper from "../../components/cards/card/CardWrapper";
import CardPopular from "../../components/cards/dashboard/CardPopular";
import DashboardLayout from "./dashboard-layout";

export default function Dashboard() {
  return (
    <DashboardLayout >
      <main className="xpx-1">
        <div className="pt-2"></div>
        <CardTitle text="Popularity" />
        <div className="py-2 my-2 flex flex-col md:flex-row gap-2 min-h-max">
          <div className="flex flex-row md:flex-col flex-wrap gap-2 items-stretch content-stretch min-w-40">
            <CardPopular limit={30} />
            <CardPopular limit={7} />
            <CardPopular limit={1} />
          </div>

          <div className="flex-grow flex-shrink">
            <CardWrapper>
              <div className="h-[226px] py-2">
                <CardTitle text="Statistics" />
                <div className=" text-center xrelative top-[50%]  xtranslate-y-[-50%]">
                  <Error
                    fontSize="large"
                    sx={{
                      fontSize: 80,
                      p: 2,
                      borderRadius: "50%",
                      backgroundColor: "#00000015",
                    }}
                  />
                  <div className="text-[#4979c0] font-bold text-2xl pt-5 pb-2">
                    Oops!
                  </div>
                  <div className="text-xs">
                    Look like this section is not done yet.
                  </div>
                </div>
              </div>
            </CardWrapper>
          </div>
        </div>

        <CardTitle text="Requests" />

        <div className="pt-3"></div>

        <CardWrapper hover>
          <div className="flex flex-nowrap w-full gap-2 justify-between p-4">
            <div className="flex-grow-1">
              <h1>first section</h1>
              <h1>first section</h1>
            </div>
            <div className="inline-block  h-[90%] w-[3px] bg-[#4fbccd]"></div>
          </div>
        </CardWrapper>
      </main>
    </DashboardLayout>
  );
}

