
import { useEffect, useState } from "react";

export default function RadioGroup({
  data,
  selectedIndex,
  onChange,
}: {
  data: Array<string>;
  selectedIndex: number;
  onChange: (index: number, val: string) => void;
}) {
  return (
    <div>
      {data.map((d: any, index) => (
        <div key={index} className="inline p-1">
          <input
            checked={selectedIndex === index}
            onChange={() => onChange(index, d)}
            type="radio"
            name="video-type"
            id={`vt-${index}`}
          />
          <label htmlFor={`vt-${index}`}> {d}</label>
        </div>
      ))}
    </div>
  );
}

