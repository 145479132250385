
// "use client";

import { useNavigate, useParams } from "react-router-dom";
import AppsNavigationDrawer from "../../components/AppsNavigationDrawer";
import { useEffect } from "react";
import falsyFetch from "../../_utils/falsyFetch";
import Utils from "../../utils/Utils";
import { CustomTypes } from "../../_types/CustomTypes";

// import { AppRouterCacheProvider } from '@mui/material-nextjs/v13-appRouter';
// import Constants from '@/constants/Constants';
// import { ThemeProvider, createTheme } from '@mui/material/styles';

export default function AppLayout({ children }: { children: React.ReactNode }) {

  const { app_id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (!app_id) return;
    falsyFetch("/api/app/paginate", {
      app_id: app_id,
      limit: 1,
      offset: 0,
    })
      .then(
        Utils.safeRetData((data: CustomTypes.AppType[]) => {
          if (data.length === 0) {
            navigate('/');
            alert("App not found!");
            return;
          }

        })
      )
      .catch(Utils.handleCatchError);
  }, [app_id]);

  return (
    <section className=" w-screen h-dvh overflow-hidden flex xflex-col flex-row xflex-wrap xsm: flex-nowrap">
      <AppsNavigationDrawer />
      <div className="h-full w-full overflow-auto scrollbar px-3 py-3">
        {children}
      </div>
    </section>
  );
}

