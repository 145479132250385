import { Add, Cancel, Done, Save, Sort } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import { CustomTypes } from "../../../_types/CustomTypes";
import Log from "../../../_utils/log/Log";
import TextAreaNormal from "../../input/TextAreaNormal";
import TextInputNormal from "../../input/TextInputNormal";
import RoundedLinkButton from "../../link/RoundedLinkButton";
import DropdownListItem from "../../list-item/DropdownListItem";
import ModalActionDialog from "../ModalActionDialog";
import ModalDraggableItemEditor from "../ModalDraggableItemEditor";

type vt = "youtube" | "vimeo" | "video";

export function DropdownContentEditor({
  content,
  onUpdateToolbar,
  onSave,
  onChange,
}: {
  content: CustomTypes.ContentDropdownType | null;
  onUpdateToolbar: (isVisible: boolean) => void;
  onSave: (savedContent: CustomTypes.ContentDropdownType) => void;
  onChange: (cc: CustomTypes.ContentDropdownType | ((o: any) => any)) => void;
}) {
  const iframeWrapperRef = useRef(null);

  const [itemIndexToEdit, setItemIndexToEdit] = useState<number>(-1);
  const [itemIndexToDelete, setItemIndexToDelete] = useState(-1);
  const [isSortModalVisible, setIsSortModalVisible] = useState(false);

  // Example usage:
  // const videoUrl =
  //   "https://sample-dropdowns.com/video321/mp4/480/big_buck_bunny_480p_5mb.mp4";

  // createThumbnail(videoUrl, thumbnailWidth, thumbnailHeight);

  // useEffect(() => {
  //   Log.exp("content received: ", content);
  //   onChange(content);
  // }, [content]);
  useEffect(() => {
    if (itemIndexToEdit !== -1) {
      onUpdateToolbar(false);
    } else {
      onUpdateToolbar(true);
    }
  }, [itemIndexToEdit, onUpdateToolbar]);

  const functionHandler = {
    saveContent() {
      if (!content) return;

      onSave(content);
    },
    addNewDropdown() {
      
      if (!content) return;

      if (!Array.isArray(content.dropdowns)) content.dropdowns = [];

      // content.dropdowns.push();
      setItemIndexToEdit(content.dropdowns.length);
    },

    onCancelEditview() {
      setItemIndexToEdit(-1);
    },
    onDoneEditView(dropdownItem: CustomTypes.DropdownType) {
      Log.exp("dropdown edited: ", dropdownItem);

      onChange((o) => {
        if (!o) return o;
        if (!o.dropdowns) o.dropdowns = [];

        o.dropdowns.splice(itemIndexToEdit, 1, dropdownItem);
        setItemIndexToEdit(-1);
        return o;
      });
    },
    onCancelDeleteview() {
      setItemIndexToDelete(-1);
    },
    onDoneDeleteView() {},
  };

  const ItemEditView = ({ itemIndex }: { itemIndex: number }) => {
    const [mDropdownItem, setMDropdownItem] =
      useState<CustomTypes.DropdownType>({
        content_id: content?.content_id || null,
        description: "",
        ordinal: 0,
        title: "",
        content_dropdown_id: null,
      });

    useEffect(() => {
      if (itemIndex === -1 || !content) return;

      let item = content?.dropdowns[itemIndex];
      if (!item) {
        item = {
          content_id: content?.content_id,
          description: "",
          ordinal: 0,
          title: "",
          content_dropdown_id: null,
        };
      }

      setMDropdownItem(item);
    }, [itemIndex]);

    return (
      <div className="xabsolute xsticky top-0 h-full  xbg-[#565] flex flex-col items-start gap-2 justify-start">
        <div className="pt-2"></div>

        <label className="font-bold" htmlFor="description">
          Title :
        </label>
        <TextInputNormal
          type="text"
          fullWidth
          // rows={10}
          value={mDropdownItem.title}
          onChange={(e) => {
            const title = e.target.value;
            setMDropdownItem((o) => {
              return {
                ...o,
                title: title,
              };
            });
          }}
        />
        <label className="font-bold" htmlFor="description">
          Description :
        </label>
        <TextAreaNormal
          fullWidth
          rows={10}
          value={mDropdownItem.description}
          onChange={(e) => {
            const desc = e.target.value;
            setMDropdownItem((o) => {
              return {
                ...o,
                description: desc,
              };
            });
          }}
        />

        <div className="flex flex-row items-center justify-center w-full gap-2 pt-5">
          <RoundedLinkButton
            icon={<Cancel />}
            title="Cancel"
            onClick={functionHandler.onCancelEditview}
          />
          <RoundedLinkButton
            icon={<Done />}
            title="Done"
            onClick={() => {
              functionHandler.onDoneEditView(mDropdownItem);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="p-3 pb-10 h-full  xoverflow-auto ">
      {itemIndexToEdit !== -1 ? (
        <ItemEditView itemIndex={itemIndexToEdit} />
      ) : (
        <>
          <ModalActionDialog
            isOpen={itemIndexToDelete !== -1}
            onClose={() => {
              setItemIndexToDelete(-1);
            }}
            title="Delete video from list"
            innerContent={<div>delete dropdown</div>}
            actions={[
              {
                title: "Delete",
                onClick() {
                  onChange((o) => {
                    if (!o) return o;
                    if (!o.dropdowns) o.dropdowns = [];

                    o.dropdowns.splice(itemIndexToDelete, 1);

                    return o;
                  });
                  setItemIndexToDelete(-1);
                },
                position: "right",
              },
              {
                title: "Cancel",
                onClick() {
                  setItemIndexToDelete(-1);
                },
                position: "right",
              },
            ]}
          />

          <ModalDraggableItemEditor
            items={content?.dropdowns as CustomTypes.DropdownType[]}
            identifier={"ordinal"}
            ordinal="ordinal"
            filterables={["title", "description"]}
            title="title"
            subtitle="description"
            onClose={function (): void {
              setIsSortModalVisible(false);
            }}
            isOpen={isSortModalVisible}
            onChange={(items) => {
              onChange((o) => {
                return {
                  ...o,
                  dropdowns: items,
                };
              });
            }}
          />

          <div className="flex pb-2 justify-between sticky top-[90px] bg-[#0a212b]">
            <RoundedLinkButton
              icon={<Add />}
              title="Add new Dropdown"
              onClick={functionHandler.addNewDropdown}
            />

            <RoundedLinkButton
              icon={<Sort fontSize="small" />}
              title="Sort"
              onClick={() => {
                if (!content) return;
                setIsSortModalVisible(true);
              }}
            />
            <RoundedLinkButton
              icon={<Save fontSize="small" />}
              title="Save"
              onClick={() => {
                if (!content) return;
                onSave(content);
              }}
            />
          </div>
          {!!content?.dropdowns &&
            !!content?.dropdowns.map &&
            content?.dropdowns.map((a, i) => (
              <DropdownListItem
                key={i}
                index={i}
                item={a}
                editable
                onEdit={function (index: number): void {
                  setItemIndexToEdit(index);
                }}
                onDelete={function (index: number): void {
                  setItemIndexToDelete(index);
                }}
              />
            ))}
        </>
      )}
    </div>
  );
}
