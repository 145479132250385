"use client";

import { CircularProgress, ClickAwayListener } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import CardWrapper from "../cards/card/CardWrapper";
import CardInner from "../cards/card/CardInner";
import UnderConstruction from "../UnderConstruction";
import CardTitle from "../cards/card/CardTitle";
import RoundedLinkButton from "../link/RoundedLinkButton";
import {
  CancelRounded,
  CheckCircleRounded,
  Close,
  Create,
  Save,
} from "@mui/icons-material";

import TextInputNormal from "../input/TextInputNormal";
import React from "react";
import falsyFetch from "../../_utils/falsyFetch";
import Utils from "../../utils/Utils";
import ModalActionDialog from "./ModalActionDialog";
import { CustomTypes } from "../../_types/CustomTypes";

type ModalProps = {
  onClose: () => void;
  isOpen: boolean;
  onSave: (apId: number) => void;
};

export default function ModalAddApp({ onClose, isOpen, onSave }: ModalProps) {
  const scrollerRef = useRef(null);

  const [appName, setAppName] = useState("");
  const [appSlug, setAppSlug] = useState("");
  const [appSlugError, setAppSlugError] = useState("");
  const [packageName, setPackageName] = useState("");

  const [slugValidationLoading, setSlugValidationLoading] = useState(false);
  const [isSlugValid, setIsSlugValid] = useState(false);
  const [timeoutId, setTimeoutId] = useState<any>(null);

  const [isAppCreating, setIsAppCreating] = useState(false);

  // const [callback, setCallback] = useState<any>(null);

  useEffect(() => {
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  useEffect(() => {
    const regx = /^[a-z0-9_-]+$/;

    if (typeof appSlug !== "string" || appSlug.length <= 3) {
      return setAppSlugError("Slug length must be more than 3 character");
    }
    if (!regx.test(appSlug)) {
      return setAppSlugError(
        "Slug must be combination of letter, number and _ "
      );
    }
    setAppSlugError("");

    functionHandler.validateSlug(appSlug);
  }, [appSlug]);

  const functionHandler = {
    replaceNonMatchingCharacters(str: string) {
      // Replace any characters that don't match the regex with an empty string
      const rx = /[^a-z0-9_-]/g;
      // alert(rx.source);
      return str.replace(rx, "");
    },
    onSave() {
      setIsAppCreating(true);

      const apps: CustomTypes.AppType[] = [];

      apps.push({
        description: appName,
        package_name: packageName,
        slug: appSlug,
        title: appName,
      } as any);

      falsyFetch("/api/app/upsert", {
        apps,
      })
        .then(
          Utils.safeRetData((data) => {
            const id = data[0].id;
            onSave(id);
            // console.log("app inserted: ", data);
          })
        )
        .catch(Utils.handleCatchError)
        .finally(() => setIsAppCreating(false));

      // setTimeout(() => {
      //   onSave(1);
      // }, 7000);
    },
    validateSlug(slug: string) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      setSlugValidationLoading(true);
      setIsSlugValid(false);

      const newTimeoutId = setTimeout(() => {
        falsyFetch(
          "/api/slug/validate",
          {
            slug: slug,
          }
          // (payload) => {
          //   return {
          //     ret_data: {
          //       slug: payload.slug,
          //       is_valid: true,
          //     },
          //     error: null,
          //   };
          // }
        )
          .then(
            Utils.safeRetData((data) => {
              //data to do anything.
              if (!data.is_valid) {
                setAppSlugError("The slug can't be used.");
              }

              setIsSlugValid(data?.is_valid || false);
            })
          )
          .catch(Utils.handleCatchError)
          .finally(() => setSlugValidationLoading(false));
      }, 500);

      setTimeoutId(newTimeoutId);
    },
  };

  return (
    <div
      id="modal-media-editor-top"
      className={`${
        isOpen ? "block" : "hidden"
      } fixed left-0 top-0 w-dvw h-dvh overflow-hidden bg-[#0000006e] z-50
            flex flex-col items-center justify-center
            `}
    >
      <ModalActionDialog
        title="Info!"
        hideCloseButton
        isOpen={isAppCreating}
        onClose={() => {}}
        actions={[]}
        innerContent={
          <div className="flex flex-col items-center justify-center gap-3">
            <CircularProgress
              sx={{
                color: "inherit",
              }}
              size={38}
              thickness={6}
            />
            <span>Please wait while your app is being created.</span>
          </div>
        }
      />
      {isOpen && (
        <ClickAwayListener
          onClickAway={(e) => {
            // onClose();
          }}
        >
          <div
            className="rounded-lg border-2 border-[#ffffff7b] shadow-md
                    max-w-[650px] max-h-[95dvh] overflow-y-auto w-full"
            ref={scrollerRef}
          >
            <div className="w-full">
              <div className="bg-dark-blue-100">
                <div className="sticky top-0 z-10 bg-dark-blue-100 flex items-center pt-2 justify-between px-2">
                  <CardTitle text="Create New App" />
                  <RoundedLinkButton
                    icon={<Close fontSize="small" />}
                    onClick={onClose}
                  />
                </div>
                <div className="pt-2"></div>

                <CardInner>
                  <div className="w-full">
                    <p className="pb-2">App Name:</p>

                    <TextInputNormal
                      type="text"
                      value={appName}
                      placeholder="Test App"
                      onChange={(e) => {
                        const name = e.target.value;
                        let slug = name
                          .replaceAll(/ +/g, " ")
                          .replaceAll(" ", "_")
                          .toLowerCase();

                        slug =
                          functionHandler.replaceNonMatchingCharacters(slug);

                        setAppSlug(slug);

                        setAppName(name);
                      }}
                    />

                    <p className="pb-2">App Slug:</p>

                    <div className="flex flex-row gap-2 items-center">
                      <TextInputNormal
                        type="text"
                        value={appSlug}
                        placeholder="test-app"
                        // errorMessage={appSlugError}
                        onChange={(e) => {
                          const val = e.target.value.toLowerCase();

                          setAppSlug(val);
                        }}
                      />

                      {slugValidationLoading ? (
                        <CircularProgress
                          thickness={8}
                          sx={{
                            color: "inherit",
                          }}
                          size={22}
                        />
                      ) : isSlugValid ? (
                        <CheckCircleRounded color="success" />
                      ) : (
                        <CancelRounded color="error" />
                      )}
                    </div>
                    <span className="block xabsolute p-1 bottom-0 left-0 text-[#e9a33a] text-sm">
                      {appSlugError ? "** " + appSlugError : null}
                    </span>

                    <p className="pb-2">Android Package Name:</p>

                    <TextInputNormal
                      type="text"
                      value={packageName}
                      placeholder="com.example.app"
                      onChange={(e) => {
                        const val = e.target.value;
                        setPackageName(val);
                      }}
                    />

                    <div className="p-6"></div>

                    <RoundedLinkButton
                      disabled={!isSlugValid}
                      className="self-center"
                      icon={
                        <Create
                          sx={{
                            fontSize: 16,
                          }}
                        />
                      }
                      title=" Create App"
                      onClick={functionHandler.onSave}
                    />
                  </div>
                </CardInner>
              </div>
            </div>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
}
