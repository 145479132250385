import { CustomTypes } from "../_types/CustomTypes";
import Constants from "../utils/Constants";
import Utils from "../utils/Utils";
import sleep from "./sleep";

async function falsyFetch<T>(
  url: string,
  payload: T,
  task?: ((payload: T) => any) | null
): Promise<CustomTypes.RetData<any>> {
  //if need to fake the api
  if (task) {
    await sleep(500);
    return task(payload);
  }

  // debugger;

  try {
    const fres = await fetch(
      url.includes("://") ? url : Constants.baseAPIUrl + url,
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        // credentials: "omit", // include, *same-origin, omit
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${Utils.getCookie(
            Constants.key.CookiesKey.loginToken
          )}`,
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        // redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(payload || {}), // body data type must match "Content-Type" header
      }
    );
    // return response.json(); // parses JSON response into native JavaScript objects

    // postData("https://example.com/answer", { answer: 42 }).then((data) => {
    //     console.log(data); // JSON data parsed by `data.json()` call
    // });

    // const mres = await fres.text();
    // console.log('mres : ', mres);
    // return JSON.parse();
    return await fres.json();
  } catch (e: any) {
    return {
      ret_data: [],
      error: {
        info: e.message,
        message: e.message,
        trace: e.stack,
      },
    };
  }
}

export default falsyFetch;
