
"use client";

import { Add } from "@mui/icons-material";
import { Box, Container, Typography } from "@mui/material";
import CardTitle from "../../components/cards/card/CardTitle";
import AppCard from "../../components/cards/dashboard/AppCard";
import RoundedLinkButton from "../../components/link/RoundedLinkButton";
import DashboardLayout from "./dashboard-layout";
import React, { useEffect, useState } from "react";
import { CustomTypes } from "../../_types/CustomTypes";
import falsyFetch from "../../_utils/falsyFetch";
import FetcherProvider, { useFetcher } from "../../_utils/fetcher/fetcher";
import ModalAddApp from "../../components/modals/ModalAddApp";
import { useNavigate } from "react-router";

export default function Apps() {
  // const [apps, setApps] = useState<Array<CustomTypes.AppType>>([]);
  const [isAddAppDialogVisible, setIsAddAppDialogVisible] = useState(false);
  const [appBinder, appFetch, appUpdateBinder] = useFetcher<
    {
      limit: number;
      offset: number;
      search_query?: string;
    },
    Array<CustomTypes.AppType>
  >("/api/app/paginate");

  const navigate = useNavigate();

  useEffect(() => {
    let payload = {
      limit: -1,
      offset: 0,
    };

    appFetch(payload);
  }, []);

  const fnctionHandler = {};

  return (
    <DashboardLayout>
      <ModalAddApp
        onClose={function (): void {
          setIsAddAppDialogVisible(false);
        }}
        isOpen={isAddAppDialogVisible}
        onSave={function (appId: number): void {
          navigate("/apps/" + appId);
        }}
      />
      <main>
        <div className="pt-5"></div>
        <div className="flex flex-row flex-wrap gap-3">
          <CardTitle text="Apps" />
          <RoundedLinkButton
            onClick={() => {
              setIsAddAppDialogVisible(true);
            }}
            icon={<Add />}
            iconPosition="end"
            title="Add app"
          />
        </div>
        <div className="pt-5"></div>
        <div className="flex flex-row flex-wrap  flex-shrink gap-3">
          <FetcherProvider
            fetcherBinder={appBinder}
            onResult={(data) => (
              <>
                {(data || []).map((app) => (
                  <AppCard key={app.app_id} app={app} />
                ))}
              </>
            )}
          />
        </div>
      </main>
    </DashboardLayout>
  );
}