
"use client";

import { ClickAwayListener } from "@mui/material";
import { ReactNode, useEffect, useRef, useState } from "react";
import CardWrapper from "../cards/card/CardWrapper";
import CardInner from "../cards/card/CardInner";
import UnderConstruction from "../UnderConstruction";
import CardTitle from "../cards/card/CardTitle";
import RoundedLinkButton from "../link/RoundedLinkButton";
import { Close, Save } from "@mui/icons-material";
import TextInputNormal from "../input/TextInputNormal";
import Clickable from "../Clickable";
import ElevatedButton from "../button/ElevatedButton";

type ModalProps = {
  innerContent?: string | ReactNode;
  title: string;
  onClose: () => void;
  hideCloseButton?: boolean;
  isOpen: boolean;
  actions: Array<{
    icon?: ReactNode;
    title: string;
    onClick: () => void;
    position: "left" | "right" | "center";
  }>;
};

export default function ModalActionDialog({
  title,
  onClose,
  innerContent,
  hideCloseButton = false,
  isOpen,
  actions,
}: ModalProps) {
  // const [callback, setCallback] = useState<any>(null);

  // useEffect(() => {

  //     setUrlInputValue(imageUrl || '');
  //     setLoadedUrl(imageUrl || '');

  // }, [isOpen, imageUrl]);

  // function loadImage(url: string) {
  //     setLoadedUrl(url);

  //     (scrollerRef?.current as any).scrollTo && (scrollerRef.current as any).scrollTo(0, 0);
  // }

  return (
    <div
      id="modal-media-editor-top"
      className={`fixed left-0 top-0 w-dvw h-dvh overflow-hidden bg-[#0000006e] z-50 flex flex-col items-center justify-center ${
        isOpen ? "block" : "hidden"
      }`}
    >
      {isOpen && (
        <ClickAwayListener
          onClickAway={(e) => {
            onClose();
          }}
        >
          <div
            className="rounded-lg border-2 border-[#ffffff7b] shadow-md max-w-[650px] max-h-[95dvh] overflow-y-auto w-full"
            // ref={scrollerRef}
          >
            <div className="w-full">
              <div className="bg-dark-blue-100">
                <div className="sticky top-0 z-10 bg-dark-blue-100 flex items-center pt-2 justify-between px-2">
                  <CardTitle text={title} />
                  {!hideCloseButton && (
                    <RoundedLinkButton
                      icon={<Close fontSize="small" />}
                      onClick={onClose}
                    />
                  )}
                </div>
                <div className="pt-2"></div>

                <div className="p-2">{innerContent as any}</div>
                <div className="py-3 px-3 flex flex-row items-center justify-between">
                  <div className="left-menu flex flex-row flex-wrap">
                    {actions
                      .filter((a) => a.position === "left")
                      .map((a, i) => (
                        <ElevatedButton
                          key={i}
                          color="#ddd"
                          onClick={a.onClick}
                        >
                          {a.icon}
                          {a.title}
                        </ElevatedButton>
                      ))}
                  </div>
                  <div className="center-menu flex flex-row flex-wrap">
                    {actions
                      .filter((a) => a.position === "center")
                      .map((a, i) => (
                        <ElevatedButton key={i} onClick={a.onClick}>
                          {a.icon}
                          {a.title}
                        </ElevatedButton>
                      ))}
                  </div>
                  <div className="right-menu flex flex-row flex-wrap">
                    {actions
                      .filter((a) => a.position === "right")
                      .map((a, i) => (
                        <ElevatedButton key={i} onClick={a.onClick}>
                          {a.icon}
                          {a.title}
                        </ElevatedButton>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
}

