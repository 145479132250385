import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./app/pages/dashboard/dashboard";
import Settings from "./app/pages/dashboard/settings";
import Apps from "./app/pages/dashboard/apps";
import AppDetails from "./app/pages/apps/app-details";
import NavItemDetails from "./app/pages/apps/nav/nav-item-details";
import { useEffect, useState } from "react";
import UnderConstruction from "./app/components/UnderConstruction";
import { AuthProvider } from "./app/providers/AuthProvider";
import { CookiesProvider } from "react-cookie";

function App() {
  return (
    <CookiesProvider defaultSetOptions={{ path: "/" }}>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/apps" element={<Apps />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/apps/:app_id" element={<AppDetails />} />
            <Route
              path="/apps/:app_id/nav/:nav_item_slug"
              element={<NavItemDetails />}
            />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </CookiesProvider>
  );
}

export default App;
