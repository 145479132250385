
import { useEffect, useState } from "react";
import ElevatedButton from "./ElevatedButton";

export default function MultiselectButton({
  selectedIndex,
  items,
  onChange,
}: {
  selectedIndex: number;
  items: Array<string>;
  onChange: (index: number, title: string) => void;
}) {
  const [slIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    setSelectedIndex(selectedIndex);
  }, [selectedIndex, items]);

  return (
    <div className="flex flex-row xgap-2 rounded-xl overflow-hidden border-2 border-[#dddddd44]">
      {items.map((item, index) => (
        <div
          key={index}
          className={` ${
            slIndex === index
              ? " bg-[#0f2132af] "
              : " bg-[#dddddd15]  hover:bg-[#f1f1f14e]"
          } xm-1 py-1 px-2 shadow-sm shadow-[#dddddd50] select-none xrounded-lg active:bg-[#5a5a5a3a] flex flex-row gap-1  cursor-pointer`}
          onClick={() => {
            setSelectedIndex(index);
            onChange(index, item);
          }}
        >
          {item}
        </div>
      ))}
    </div>
  );
}

