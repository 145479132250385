import { Delete, Edit } from "@mui/icons-material";
import { CustomTypes } from "../../_types/CustomTypes";
import React, { useEffect, useState } from "react";
import falsyFetch from "../../_utils/falsyFetch";
import Log from "../../_utils/log/Log";
import { CircularProgress } from "@mui/material";
import RoundedLinkButton from "../link/RoundedLinkButton";

export default function VideoListItem({
  index,
  item,
  editable = false,
  thumbWidth = 130,
  onEdit,
  onDelete,
}: {
  index: number;
  item: CustomTypes.VideosType;
  editable?: boolean;
  thumbWidth?: number;
  onEdit?: (index: number) => void;
  onDelete?: (index: number) => void;
}) {
  const [thumbnail, setThumbnail] = useState("/thumbnail-placeholder.jpg");
  const [isThumbnailLoading, setIsThumbnailLoading] = useState(false);

  useEffect(() => {
    if (item.url) {
      setIsThumbnailLoading(true);
      functionHandler
        .generateThumbnail(item.type, item.url)
        .then((val) => {
          if (!val) return;
          setThumbnail(val);
        })
        .finally(() => {
          setIsThumbnailLoading(false);
        });
    }
  }, [item.url, item.type]);

  const functionHandler = {
    generateThumbnail(
      videoType: "youtube" | "vimeo" | "video",
      videoUrl: string
    ): Promise<string | null> {
      return new Promise((res) => {
        falsyFetch("/api/utils/generate-thumbnail", {
          type: videoType,
          url: videoUrl,
        })
          .then((rsp) => {
            Log.exp("resp :", rsp);
            res(rsp.ret_data.thumbnail);
          })
          .catch((e) => {
            res(null);
          });
        // res(`http://img.youtube.com/vi/${videoUrl}/0.jpg`);
      });
    },
  };

  return (
    <div className="flex flex-row gap-1 items-start justify-stretch bg-[#0f2132af] border-2 rounded-lg border-[#dddddd27] m-1 p-2">
      <div className="h-full flex items-center justify-center">
        {isThumbnailLoading ? (
          <div>
            <CircularProgress size="65px" />
          </div>
        ) : (
          <img
            className="shrink-0 rounded-lg overflow-hidden"
            style={{
              objectFit: "cover",
            }}
            width={thumbWidth}
            height={(7 / 12) * thumbWidth}
            src={thumbnail}
            alt=""
          />
        )}
      </div>
      <div className="px-2 xbg-[#0a0aa5] xshrink-0 grow  flex flex-col justify-between gap-1">
        <h1 className="font-bold text-lg">{item.topic}</h1>
        <p
          title="desc"
          className={`bg-transparent  xw-full text-wrap
              line-clamp-2 truncate xtext-ellipsis 
            `}
        >
          {item.description}
        </p>
        {/* <div className={`flex items-center justify-center `}>
            <RoundedLinkButton icon={null} title="Done" />
            <RoundedLinkButton
              icon={null}
              title="Cancel"
              onClick={() => {
                // setIsEditing(false);
                setItemToEdit(null);
              }}
            />
          </div> */}
      </div>
      {editable && (
        <div className={``}>
          <RoundedLinkButton
            icon={<Edit />}
            onClick={() => {
              // setIsEditing(true);
              onEdit && onEdit(index);
            }}
          />
          <RoundedLinkButton
            icon={<Delete />}
            onClick={() => {
              onDelete && onDelete(index);
            }}
          />
        </div>
      )}
    </div>
  );
}
