import { GlobalStylesProps } from "@mui/material";
import { Navigate } from "react-router-dom";

export default function RoundedLinkButton({
  className = "",
  iconPosition = "start",
  icon,
  title = undefined,
  href,
  outline = false,
  onClick = () => {},
  center = false,
  disabled = false,
}: {
  iconPosition?: "start" | "end";
  className?: string;
  icon: any;
  title?: string;
  href?: string;
  outline?: boolean;
  onClick?: () => void;
  center?: boolean;
  disabled?: boolean;
}) {
  const child = (
    <div
      className={
        className +
        `flex gap-2 flex-row rounded-full w-full flex-nowrap text-center border-2 px-3 xpx-1  items-center py-1 
        ${
          center ? "justify-center" : ""
        }
         bg-dark-blue-100 hover:border-[#4478907c] 
        
        ${
          outline ? "border-[#93b4e923]" : "border-transparent outline-none"
        } 
        ${
          disabled ? ' ' : ' focus:ring focus:ring-[#1c3c5100] active:bg-[#3d597b66] '
        }
        
        `
      }
    >
      {iconPosition === "start" && icon}
      {title && <span className="font-semibold">{title}</span>}
      {iconPosition === "end" && icon}
    </div>
  );

  return href ? (
    <Navigate to={href} />
  ) : (
    <div className={`${disabled ? '' : 'cursor-pointer'} `} onClick={!disabled ? onClick : () => {}}>
      {child}
    </div>
  );
}
