
import { ChangeEvent, HTMLInputTypeAttribute } from "react";

export default function TextInputNormal({
  fullWidth = false,
  value,
  onChange,
  type,
  placeholder,
  disabled = false,
  errorMessage = "", // Optionally, you can include an error message prop
}: {
  fullWidth?: boolean;
  placeholder?: string;
  type: HTMLInputTypeAttribute;
  value?: any;
  disabled?: boolean;
  errorMessage?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}) {
  const error = !!errorMessage;

  const condCss = "w-full ";
  return (
    <div className={`relative ${fullWidth ? " w-full" : ""}`}>
      <input
        className={`rounded-lg p-2 outline-none focus:outline-1 focus:outline-[#2e6e74bc] bg-[#0000005b] focus:bg-[#20202061] ${
          fullWidth ? " w-full xshrink-0" : ""
        } ${error ? "border-red-500" : ""}`} // Add a border or any style to indicate error
        type={type}
        disabled={disabled}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />

      {error && errorMessage && (
        <span className="block xabsolute p-1 bottom-0 left-0 text-[#e9a33a] text-sm">
          ** {errorMessage}
        </span>
      )}
    </div>
  );
}

