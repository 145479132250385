"use client";

export default function CardWrapperCl({
    children, color, hover = false, grow = true,
    onClick = () => { },
}: {
    color?: string; children?: any; hover?: boolean;
    grow?: boolean;
    onClick?: () => void;
}) {

    const ccolor = color ? `bg-[${color}]` : 'bg-dark-blue-100';

    return (<div onClick={onClick} className={` flex-shrink ${grow
        ? "flex-grow"
        : "flex-grow-0"
        } shadow-md overflow-hidden ${ccolor
        }  shadow-[#121b246d] rounded-lg min-w-max `}>
        <div className={`${hover ? 'hover:bg-[#bbe8e817] cursor-pointer' : ''
            } min-w-max  px-2 py-1 `}>
            {children}
        </div>
    </div>)
}