
"use client";

import { forwardRef } from "react";

function Clickable({
    children,
    onClick,
    className,
}: {
    className?: string;
    onClick: () => void;
    children?: any
}, ref: any) {

    return (
        <div
            ref={ref}
            className={(className || "") + " cursor-pointer"}
            onClick={onClick}
        >
            {children}
        </div>
    )
}



export default forwardRef(Clickable);