import {
    Dispatch,
    ReactNode,
    SetStateAction,
    createContext,
    memo,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import falsyFetch from "../falsyFetch";
import sleep from "../sleep";
import {CircularProgress} from "@mui/material";
import DefaultLoadingView from "./default-loading-view";
import _ from "lodash";
import Constants from "../../utils/Constants";

type BinderDataType<D> = {
    data?: D | null;
    isLoading?: boolean;
    isError?: boolean;
    error?: any;
};

const TIMEOUT_ERROR = 10000;

function setRet<T extends any[]>(arr: T): T {
    return arr;
}

function tuple<T extends any[]>(...args: T): T {
    return args;
}

export function useFetcher<P, R>(
    url: string,
    task?: ((payload: P) => R | null) | null
) {
    const [prevPayload, setPrevPayload] = useState<any>({});

    const [fbinder, setFbinder] = useState<BinderDataType<R>>({
        data: null,
        error: null,
        isLoading: false,
        isError: false,
    });

    function updateBinder(
        bData:
            | BinderDataType<R>
            | ((data: BinderDataType<R>) => BinderDataType<R>) = {
            data: null,
            error: null,
            isError: false,
            isLoading: false,
        }
    ) {
        setFbinder((o) => {
            const binderData = typeof bData === "function" ? bData(o) : bData;

            const od = _.cloneDeep(o);
            const dd = _.cloneDeep(binderData);

            const nd = {
                ...od,
                ...dd,
            };

            return _.isEqual(o, nd) ? o : nd;
        });
    }

    async function quickFetch<T extends P>(
        payload: T,
        silent = false,
        refresh = false
    ): Promise<void> {
        // console.log('inside quick fetch: ', url, " p: ", payload);
        // if (!echo) {
        //     task = null;
        // }

        if (!refresh && _.isEqual(prevPayload, payload)) {
            // console.log("returning quickFetch bcoz payload is same: ", prevPayload, payload);
            return;
        }

        setPrevPayload(payload);

        // const url = '/api/' + path;
        let res = null;

        // console.log('qf: ', 'onloadingstate change true');

        const bd: BinderDataType<R> = {
            isLoading: !silent,
            isError: false,
            error: null,
        };

        if (!silent) {
            bd.data = null;
        }

        updateBinder(bd);

        try {
            // console.log('qf: ', 'try ');

            // debugger;

            // eslint-disable-next-line no-restricted-globals
            // let fullUrl = Constants.baseAPIUrl + url;
            res = await falsyFetch(url, payload, task as any);

            if (res && res.error) {
                console.log("fetcher error: ", res.error);
                updateBinder({
                    isError: true,
                    error: res.error,
                });
            }
        } catch (e) {
            // console.log('qf: ', 'catch: ', e);
            updateBinder({
                isError: true,
                error: e,
            });
        }

        // console.log('qf: ', 'onloadingstatechanged false ');

        updateBinder({
            isLoading: false,
            data: res?.ret_data || res,
        });

        // return res;

        // falsyFetch(url, payload, task)
        //     .then((_res) => {

        //     })
        //     .catch((_reason) => {

        //     })
        //     .finally(() => {
        //         _fb?.onLoadingStateChanged(false);
        //     });
    }

    const ret = tuple(fbinder, quickFetch, updateBinder);
    return ret;

    // // eslint-disable-next-line react-hooks/rules-of-hooks
    // const [_, setFetchData] = useState<{
    //     fetchId: number;
    //     url: string;
    //     payload: any;
    //     task: any;
    // }[]>([]);
    // // eslint-disable-next-line react-hooks/rules-of-hooks
    // const [_fb, fetcherBinder] = useState<binderType>(null);
    // const [isLoading, setIsLoading] = useState(false);
    // const [isError, setIsError] = useState(false);
    // const [isDone, setIsDone] = useState(false);

    // function storeFetchData<T>(url: string, payload: T, task?: ((payload: T) => any) | null) {

    //     let eid = _.length + 1;

    //     setFetchData(
    //         (o) => {

    //             return [
    //                 ...o,
    //                 {
    //                     fetchId: eid,
    //                     url,
    //                     payload,
    //                     task
    //                 }
    //             ]
    //         }
    //     );
    //     return eid;
    // }

    // async function raiseError(res: any, fetchId: number, error: any): Promise<any> {

    //     const retry = _fb?.onNewError({
    //         errorId: fetchId,
    //         error
    //     });

    //     if (retry) {
    //         const fetchData = {
    //             ...[
    //                 ..._.splice(_.findIndex(o => o.fetchId === fetchId), 1)
    //             ][0]
    //         };

    //         if (!fetchData) return;

    //         res = await quickFetch(fetchData.url, fetchData?.payload, fetchData?.task);
    //     }

    //     return res;

    //     // _fb?.onRemoveError(eid);

    // }

    // function reset() {
    //     _fb?.onClearState()
    // }

    // async function quickFetch<T>(url: string, payload: T, task?: ((payload: T) => any) | null) {

    //     console.log('inside quick fetch: ', url, " p: ", payload);
    //     if (!echo) {
    //         task = null;
    //     }

    //     // const url = '/api/' + path;
    //     let res = null;

    //     const fetchId = storeFetchData(url, payload, task);
    //     console.log('qf: ', 'onloadingstate change true');
    //     _fb?.onLoadingStateChanged(true);

    //     try {

    //         console.log('qf: ', 'try ');
    //         res = await falsyFetch(url, payload, task);

    //         if (res && res.error) {
    //             res = await raiseError(res, fetchId, res.error);
    //         }

    //     } catch (e) {
    //         console.log('qf: ', 'catch: ', e);
    //         res = await raiseError(res, fetchId, e);
    //     }

    //     console.log('qf: ', 'onloadingstatechanged false ');
    //     _fb?.onLoadingStateChanged(false);

    //     return res;

    //     falsyFetch(url, payload, task)
    //         .then((_res) => {

    //         })
    //         .catch((_reason) => {

    //         })
    //         .finally(() => {
    //             _fb?.onLoadingStateChanged(false);
    //         });

    // }

    // return {
    //     fetcherBinder,
    //     quickFetch,
    //     reset,
    // }
}

function FetcherProvider<D>({
                                fetcherBinder,
                                onResult,
                                onLoading,
                                onError,
                            }: // children
                            {
                                fetcherBinder: BinderDataType<D>;
                                onResult: (data: D | null, error?: any) => ReactNode;
                                onLoading?: () => ReactNode;
                                onError?: (error: any | null) => ReactNode;
                                // children: any;
                            }) {
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    // const [isDone, setIsDone] = useState(false);

    const [error, setError] = useState<any>(null);
    const [data, setData] = useState<D | null>(null);

    useEffect(() => {
        const fb = fetcherBinder as any;
        if (fb && (fb.data || fb.error || fb.isError)) {
            setData(fb.data);
            setError(fb.error);
            setIsError(!!fb.isError);
            setIsLoading(!!fb.isLoading);
        }
    }, [fetcherBinder]);

    return isLoading ? (
        onLoading ? (
            onLoading()
        ) : (
            <DefaultLoadingView/>
        )
    ) : isError ? (
        onError ? (
            onError(error)
        ) : (
            onResult(data, error)
        )
    ) : (
        onResult(data, error)
    )
}

export default memo(FetcherProvider) as typeof FetcherProvider;

