import {
  Add,
  Cancel,
  Delete,
  Done,
  Edit,
  GetApp,
  Refresh,
  Save,
  Sort,
  VideoFile,
} from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { CustomTypes } from "../../../_types/CustomTypes";
import falsyFetch from "../../../_utils/falsyFetch";
import Log from "../../../_utils/log/Log";
import TextAreaNormal from "../../input/TextAreaNormal";
import TextInputNormal from "../../input/TextInputNormal";
import RoundedLinkButton from "../../link/RoundedLinkButton";
import RadioGroup from "../../radio/RadioGroup";
import ModalActionDialog from "../ModalActionDialog";
import VideoListItem from "../../list-item/VideoListItem";
import ModalDraggableItemEditor from "../ModalDraggableItemEditor";

type vt = "youtube" | "vimeo" | "video";

export function VideoContentEditor({
  content,
  onUpdateToolbar,
  onSave,
  onChange,
}: {
  content: CustomTypes.ContentVideoType | null;
  onUpdateToolbar: (isVisible: boolean) => void;
  onSave: (savedContent: CustomTypes.ContentVideoType) => void;
  onChange: (cc: CustomTypes.ContentVideoType | ((o: any) => any)) => void;
}) {
  const iframeWrapperRef = useRef(null);

  const [itemIndexToEdit, setItemIndexToEdit] = useState<number>(-1);
  const [itemIndexToDelete, setItemIndexToDelete] = useState(-1);
  const [isSortModalVisible, setIsSortModalVisible] = useState(false);

  // Example usage:
  // const videoUrl =
  //   "https://sample-videos.com/video321/mp4/480/big_buck_bunny_480p_5mb.mp4";

  // createThumbnail(videoUrl, thumbnailWidth, thumbnailHeight);

  // useEffect(() => {
  //   Log.exp("content received: ", content);
  //   onChange(content);
  // }, [content]);
  useEffect(() => {
    if (itemIndexToEdit !== -1) {
      onUpdateToolbar(false);
    } else {
      onUpdateToolbar(true);
    }
  }, [itemIndexToEdit, onUpdateToolbar]);

  const functionHandler = {
    saveContent() {
      if (!content) return;

      onSave(content);
    },
    addNewVideo() {
      if (!content) return;

      if (!Array.isArray(content.videos)) content.videos = [];

      // content.videos.push();
      setItemIndexToEdit(content.videos.length);
    },

    onCancelEditview() {
      setItemIndexToEdit(-1);
    },
    onDoneEditView(videoItem: CustomTypes.VideosType) {
      Log.exp("video edited: ", videoItem);
      if (
        !videoItem.url ||
        (videoItem.type === "video" && !videoItem.url.includes("://"))
      ) {
        alert("Pease enter a valid URL");
        return;
      }
      onChange((o) => {
        if (!o) return o;
        if (!o.videos) o.videos = [];

        o.videos.splice(itemIndexToEdit, 1, videoItem);
        setItemIndexToEdit(-1);
        return o;
      });
    },
    onCancelDeleteview() {
      setItemIndexToDelete(-1);
    },
    onDoneDeleteView() {},

    getVideoUrlPrefix(type: vt) {
      const pref =
        type === "youtube"
          ? "https://youtu.be/"
          : type === "vimeo"
          ? "https://vimeo.com/"
          : "";

      return pref;
    },

    generateIframe(videoType: "youtube" | "vimeo" | "video", videoUrl: string) {
      let ifrmStr = "";

      if (videoType === "vimeo") {
        ifrmStr =
          '<iframe width="320" height="180" src="https://player.vimeo.com/video/' +
          videoUrl +
          '?color=ff9933&portrait=0&autoplay=0" frameborder="0" allowfullscreen wmode="Opaque"></iframe>';
      } else if (videoType === "youtube") {
        ifrmStr =
          '<iframe width="320" height="180" src="https://www.youtube.com/embed/' +
          videoUrl +
          '?autoplay=0&loop=1&rel=0&wmode=transparent" frameborder="0" allowfullscreen wmode="Opaque"></iframe>';
      } else {
        if (!videoUrl.includes("://")) {
          return;
        }
        ifrmStr =
          '<iframe width="320" height="180" src="' +
          videoUrl +
          `"  frameborder="0" allowfullscreen wmode="Opaque"></iframe>`;
      }

      if (iframeWrapperRef.current) {
        (iframeWrapperRef.current as any).innerHTML = ifrmStr;
      }
    },
    generateThumbnail(
      videoType: "youtube" | "vimeo" | "video",
      videoUrl: string
    ): Promise<string | null> {
      return new Promise((res) => {
        falsyFetch("/api/utils/generate-thumbnail", {
          type: videoType,
          url: videoUrl,
        })
          .then((rsp) => {
            Log.exp("resp :", rsp);
            res(rsp.ret_data.thumbnail);
          })
          .catch((e) => {
            res(null);
          });
        // res(`http://img.youtube.com/vi/${videoUrl}/0.jpg`);
      });
    },
  };

  const ItemEditView = ({ itemIndex }: { itemIndex: number }) => {
    const videoTypes: Array<vt> = ["youtube", "vimeo", "video"];

    const [mVideoItem, setMVideoItem] = useState<CustomTypes.VideosType>({
      author: "",
      content_id: content?.content_id || null,
      content_video_id: null,
      description: "",
      ordinal: 0,
      prefix: "",
      topic: "",
      type: "video",
      url: "",
    });

    // const [thumbnailUrl, setThumbnailUrl] = useState<String | null>(null);
    const [isThumbnailLoading, setIsThumbnailLoading] = useState(false);

    useEffect(() => {
      if (itemIndex === -1 || !content) return;

      let item = content?.videos[itemIndex];
      if (!item) {
        item = {
          author: "",
          content_id: content.content_id,
          content_video_id: null,
          description: "",
          ordinal: 0,
          prefix: "",
          topic: "",
          type: "video",
          url: "",
        };
      }

      setMVideoItem(item);

      if (item.url) {
        functionHandler.generateIframe(item.type, item.url);
        // functionHandler.generateThumbnail(item.type, item.url).then((val) => {
        //   setThumbnailUrl(val);
        // });
      }
    }, [itemIndex]);

    return (
      <div className="xabsolute xsticky top-0 h-full  xbg-[#565] flex flex-col items-start gap-2 justify-start">
        <div className="flex gap-2">
          <p className="font-bold">Video Type : </p>
          <RadioGroup
            data={["Youtube", "Vimeo", "Video"]}
            selectedIndex={videoTypes.indexOf(mVideoItem.type)}
            onChange={(index, val) => {
              setMVideoItem((o) => ({
                ...o,
                type: videoTypes[index],
                url: "",
              }));
              if (iframeWrapperRef.current === null) return;
              (iframeWrapperRef?.current as any).innerHTML = "";
              Log.exp("on change: ", val, index);
            }}
          />
        </div>

        <>
          <label className="font-bold" htmlFor="">
            {mVideoItem?.type === "youtube"
              ? "Youtube ID"
              : mVideoItem?.type === "vimeo"
              ? "Vimeo ID"
              : "Video URL"}
            {":"}
          </label>
          <div className="flex items-center">
            <p className="fixed-prefix">
              {functionHandler.getVideoUrlPrefix(mVideoItem.type)}
            </p>
            <TextInputNormal
              type="url"
              fullWidth
              value={mVideoItem.url}
              onChange={(e) => {
                const url = e.target.value;
                setMVideoItem((o) => ({
                  ...o,
                  url: url,
                }));
              }}
            />
          </div>
        </>

        <div className="pt-2"></div>

        {isThumbnailLoading ? (
          <CircularProgress />
        ) : (
          <RoundedLinkButton
            icon={<VideoFile />}
            title="Load Video"
            onClick={() => {
              const vUrl =
                functionHandler.getVideoUrlPrefix(mVideoItem.type) +
                mVideoItem.url;
              if (!mVideoItem.url || !vUrl.includes("://")) {
                alert("Pease enter a valid URL");
                return;
              }
              setIsThumbnailLoading(true);
              functionHandler.generateIframe(mVideoItem.type, mVideoItem.url);
              setIsThumbnailLoading(false);
              // functionHandler
              //   .generateThumbnail(videoType, videoUrl)
              //   .then((val) => {
              //     setThumbnailUrl(val);
              //   })
              //   .finally(() => {
              //     setIsThumbnailLoading(false);
              //   });
            }}
          />
        )}

        {/* {typeof thumbnailUrl === "string" && (
          <img alt="" src={thumbnailUrl} width={160} height={90} />
        )} */}

        <div className="iframe-wrapper h-[180px]" ref={iframeWrapperRef}>
          <p></p>
        </div>

        <label className="font-bold" htmlFor="topic">
          Topic :
        </label>
        <TextInputNormal
          value={mVideoItem.topic}
          type="text"
          fullWidth
          onChange={(e) => {
            const val = e.target.value;
            setMVideoItem((o) => ({
              ...o,
              topic: val,
            }));
          }}
        />

        <label className="font-bold" htmlFor="author">
          Author :
        </label>
        <TextInputNormal
          value={mVideoItem.author}
          type="text"
          fullWidth
          onChange={(e) => {
            const val = e.target.value;
            setMVideoItem((o) => ({
              ...o,
              author: val,
            }));
          }}
        />

        <label className="font-bold" htmlFor="description">
          Description :
        </label>
        <div className="min-h-[180ox] w-full">
          <TextAreaNormal
            fullWidth
            rows={10}
            value={mVideoItem.description}
            onChange={(e) => {
              const desc = e.target.value;
              setMVideoItem((o) => {
                return {
                  ...o,
                  description: desc,
                };
              });
            }}
          />
        </div>

        <div className="flex flex-row items-center justify-center w-full gap-2 py-5">
          <RoundedLinkButton
            icon={<Cancel />}
            title="Cancel"
            onClick={functionHandler.onCancelEditview}
          />
          <RoundedLinkButton
            icon={<Done />}
            title="Done"
            onClick={() => {
              functionHandler.onDoneEditView(mVideoItem);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="p-3 pb-10 h-full  xoverflow-auto ">
      {itemIndexToEdit !== -1 ? (
        <ItemEditView itemIndex={itemIndexToEdit} />
      ) : (
        <>
          <ModalActionDialog
            isOpen={itemIndexToDelete !== -1}
            onClose={() => {
              setItemIndexToDelete(-1);
            }}
            title="Delete video from list"
            innerContent={<div>delete video</div>}
            actions={[
              {
                title: "Delete",
                onClick() {
                  onChange((o) => {
                    if (!o) return o;
                    if (!o.videos) o.videos = [];

                    o.videos.splice(itemIndexToDelete, 1);

                    return o;
                  });
                  setItemIndexToDelete(-1);
                },
                position: "right",
              },
              {
                title: "Cancel",
                onClick() {
                  setItemIndexToDelete(-1);
                },
                position: "right",
              },
            ]}
          />

          <ModalDraggableItemEditor
            items={content?.videos as CustomTypes.VideosType[]}
            identifier={"ordinal"}
            ordinal="ordinal"
            filterables={["author", "topic", "type", "description"]}
            title="topic"
            subtitle="description"
            onClose={function (): void {
              setIsSortModalVisible(false);
            }}
            isOpen={isSortModalVisible}
            onChange={(items) => {
              onChange((o) => {
                return {
                  ...o,
                  videos: items,
                };
              });
            }}
          />

          <div className="flex pb-2 justify-between sticky top-[90px] bg-[#0a212b]">
            <RoundedLinkButton
              icon={<Add />}
              title="Add new Video"
              onClick={functionHandler.addNewVideo}
            />

            <RoundedLinkButton
              icon={<Sort fontSize="small" />}
              title="Sort"
              onClick={() => {
                if (!content) return;
                setIsSortModalVisible(true);
              }}
            />
            <RoundedLinkButton
              icon={<Save fontSize="small" />}
              title="Save"
              onClick={() => {
                if (!content) return;
                onSave(content);
              }}
            />
          </div>
          {!!content?.videos &&
            !!content?.videos.map &&
            content?.videos.map((a, i) => (
              <VideoListItem
                key={i}
                index={i}
                item={a}
                editable
                onEdit={function (index: number): void {
                  setItemIndexToEdit(index);
                }}
                onDelete={function (index: number): void {
                  setItemIndexToDelete(index);
                }}
              />
            ))}
        </>
      )}
    </div>
  );
}
