
"use client";

import { ChangeEvent, HTMLInputTypeAttribute, useState } from "react";

export default function TextAreaNormal({
  fullWidth = false,
  value,
  onChange,
  rows,
  disabled = false,
  cols,
  placeholder,
}: {
  fullWidth?: boolean;
  placeholder?: string;
  rows: number;
  disabled?: boolean;
  cols?: number;
  value?: any;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}) {
  return (
    <textarea
      disabled={disabled}
      className={`rounded-lg p-2 outline-none h-max focus:outline-1 focus:outline-[#2e6e74bc] bg-[#0000005b] focus:bg-[#20202061] ${
        fullWidth ? " w-full " : ""
      }`}
      style={{
        maxLines:rows,
      }}
      rows={rows}
      cols={cols}
      value={value}
      
      placeholder={placeholder}
      onChange={onChange}
    ></textarea>
  );
}

