
// import { AppRouterCacheProvider } from '@mui/material-nextjs/v13-appRouter';
// import Constants from '@/constants/Constants';
// import { ThemeProvider, createTheme } from '@mui/material/styles';

import { ReactNode } from "react";
import NavigationDrawer from "../../components/NavigationDrawer";

export default function DashboardLayout({ children }: { children: ReactNode }) {
  return (
    <section className=" w-screen h-dvh overflow-hidden flex xflex-col flex-row xflex-wrap xsm: flex-nowrap">
      <NavigationDrawer />
      <div className="h-full w-full overflow-auto scrollbar px-3 py-3">
        {children}
      </div>
    </section>
  );
}

