



import CategorySection from "./nav-item-fg/CategorySection";
import ItemOptionSection from "./nav-item-fg/ItemOptionSection";

export default function NavItemFragment() {

    

    return (
        <>
            <ItemOptionSection />
            <div className="pt-5"></div>
            <CategorySection />
            <div className="pt-52"></div>

        </>)

}