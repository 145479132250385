import { Delete, Edit } from "@mui/icons-material";
import { CustomTypes } from "../../_types/CustomTypes";
import React, { useEffect, useState } from "react";
import falsyFetch from "../../_utils/falsyFetch";
import Log from "../../_utils/log/Log";
import { CircularProgress } from "@mui/material";
import RoundedLinkButton from "../link/RoundedLinkButton";

export default function DropdownListItem({
  index,
  item,
  editable = false,
  thumbWidth = 130,
  onEdit,
  onDelete,
}: {
  index: number;
  item: CustomTypes.DropdownType;
  editable?: boolean;
  thumbWidth?: number;
  onEdit?: (index: number) => void;
  onDelete?: (index: number) => void;
}) {

  return (
    <div className="flex flex-row gap-1 items-start justify-stretch bg-[#0f2132af] border-2 rounded-lg border-[#dddddd27] m-1 p-2">
      
      <div className="px-2 xbg-[#0a0aa5] xshrink-0 grow  flex flex-col justify-between gap-1">
        <h1 className="font-bold text-lg">{item.title}</h1>
        <p
          title="desc"
          className={`bg-transparent  xw-full text-wrap
              line-clamp-2 truncate xtext-ellipsis 
            `}
        >
          {item.description}
        </p>
        {/* <div className={`flex items-center justify-center `}>
            <RoundedLinkButton icon={null} title="Done" />
            <RoundedLinkButton
              icon={null}
              title="Cancel"
              onClick={() => {
                // setIsEditing(false);
                setItemToEdit(null);
              }}
            />
          </div> */}
      </div>
      {editable && (
        <div className={``}>
          <RoundedLinkButton
            icon={<Edit />}
            onClick={() => {
              // setIsEditing(true);
              onEdit && onEdit(index);
            }}
          />
          <RoundedLinkButton
            icon={<Delete />}
            onClick={() => {
              onDelete && onDelete(index);
            }}
          />
        </div>
      )}
    </div>
  );
}
